import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from "react-select";
import classNames from "classnames";
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import { RegxExpression } from "../../../utils/common";
import { postAddress,getAddress ,getSingleAddress,getSingleShippingAddress} from "../../../utils/apis/orderProcess/orderProcess";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City, Pincode } from "../../../utils/apis/master/Master";
import PasswordChecklist from "react-password-checklist";
const Shipping_information = ({ handlePrevious, setShippingId,ShippingId,isShippingSameAsBilling,handleNext,addressSelect,  paymentMethod, setPaymentMethod ,address_id}) => {
    const handleMethod = async (value) => {
        await setPaymentMethod(value);
    }

 
    const [showPassword, setShowPassword] = useState(false);
    // const [value, setValue] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const [country, setCountry] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [pincode, setPincode] = useState([]);
    const [Type, setType] = useState([]);
    const { signin, setSignin, shimmerLoader, setShimmerLoader, Select2Data } = useContext(Context);
    const navigate = useNavigate();
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger, setValue,
    } = useForm();

    const [sellerId, setSellerId] = useState("");
    let idis
    
  
    const onSubmit = async (data) => {
      
        setShimmerLoader(true);
        const formData = new FormData();
        if(ShippingId){
            formData.append("id", ShippingId);
        }     
        formData.append("prefix", data?.prefix);
        formData.append("l_name", data?.l_name);
        formData.append("f_name", data?.f_name);
        formData.append("m_name", data?.m_name);
        formData.append("company", data?.company);
        formData.append("state_id", data?.state_id.value);
        formData.append("country_id", data?.country_id?.value);
        
        formData.append("city_id", data?.city_id.value);
        formData.append("address", data?.address);
        formData.append("pincode_id", data?.pincode_id?.value);
        formData.append("contact_no", data?.contact_no);
        if(data?.address_type){
            formData.append("address_type", data?.address_type);
        } 
        formData.append("type", "Shipping Information");

        const res = await postAddress(formData);
        if (res?.success) {
            idis=res?.data?.id
         
           await  setShippingId(res?.data?.id);
            handleNext();
            // props.onHide()
            // props.getAllData()

        } else {

            if (res?.message?.gst_number) {
                setError("gst_number", { message: res?.message?.gst_number });
            }

        }

    };

    const GetAllCountries = async () => {
        const response = await Country();

        if (response?.success) {
            setCountry(await Select2Data(response?.data, "country_id"));
        }
    };

    const handledataSave = async () => {
       
        setType('save');
        handleSubmit(onSubmit)(); // Trigger form submission
    };

    const handledataSaveContinue = async () => {
    
        setType('saveContinue');
        handleSubmit(onSubmit)(); // Trigger form submission
    };

    const GetAllStates = async (id) => {
        const response = await State(id);

        if (response?.success) {
            setStates(await Select2Data(response?.data, "state_id"));
        }
    };

    const GetAllCities = async (id) => {
        const response = await City(id);

        if (response?.success) {
            setCities(await Select2Data(response?.data, "city_id"));
        }
    };
    const GetAllPincode = async (id) => {
        const response = await Pincode(id);

        if (response?.success) {
            setPincode(await Select2Data(response?.data, "pincode_id"));
        }
    };
   
    
    const GetEditData = async () => {
        const response = await getSingleAddress(address_id);
        reset(response?.data);
      };

      const GetEditShippingData = async () => {
        const response = await getSingleShippingAddress(ShippingId);
        reset(response?.data);
      };

    useEffect(() => {
        
        GetAllCountries();
        GetAllStates();
        GetAllCities();
        GetAllPincode();
       
    }, []);

    useEffect(() => {
        if(isShippingSameAsBilling){
            if (address_id) {
                GetEditData();             
            } else {
                GetEditShippingData();
            }
        }else{
            GetEditShippingData();
        }

        
      
    }, [isShippingSameAsBilling, ShippingId,address_id]);
     

    return (
        <>
            <section className='Addnewaddress stepp-inner-main'>
                <div className='row'>
                    <div className='col-lg-9 mx-auto'>
                        <Form>
                            {/* <Form.Group controlId="formFirstName">
                                <div className="formflex">
                                    <div className=" nameLabel mb-3">
                                    <Form.Label className="emailtxt">
                                            Prefix*
                                        </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            aria-label="Default select example"
                                            placeholder="Select Prefix"
                                            {...register("prefix", {
                                                required: "Prefix is required",
                                            })}
                                        >
                                            <option value="">Select Prefix</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Mr">Mr</option>
                                        </Form.Select>
                                        {errors.prefix && <sup className="text-danger">{errors.prefix.message}</sup>}
                                        {errors.prefix && <sup className="text-danger">{errors.prefix.message}</sup>}
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            First Name*
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="F_Name"
                                            placeholder="Enter First Name"
                                            {...register("f_name", {
                                                required: "First name required",

                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.f_name,
                                                "is-valid": getValues("f_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Middle Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="m_name"
                                            placeholder="Enter First Name"
                                            {...register("m_name", {
                                                // required: " Middle Name required",

                                            })}
                                            // className={classNames("", {
                                            //     "is-invalid": errors?.m_name,
                                            //     "is-valid": getValues("m_name"),
                                            // })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Last Name*
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="l_name"
                                            placeholder="Enter Last Name"
                                            {...register("l_name", {
                                                required: "Last Name required",

                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.l_name,
                                                "is-valid": getValues("l_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form.Group> */}
                            <Form.Group controlId="formFirstName">
                                <div className="formflex">
                                    <div className="nameLabel mb-3">
                                        <Form.Label className="emailtxt">
                                            Prefix*
                                        </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            aria-label="Default select example"
                                            placeholder="Select Prefix"
                                            {...register("prefix", {
                                                required: "Prefix is required",
                                            })}
                                        >
                                            <option value="">Select Prefix</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Mr">Mr</option>
                                        </Form.Select>
                                        {errors.prefix && <sup className="text-danger">{errors.prefix.message}</sup>}
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            First Name*
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="F_Name"
                                            placeholder="Enter First Name"
                                            {...register("f_name", {
                                                required: "First name required",

                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.f_name,
                                                "is-valid": getValues("f_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Middle Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="m_name"
                                            placeholder="Enter Middle Name"
                                            {...register("m_name", {
                                                // required: " Middle Name required",

                                            })}
                                            
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Last Name*
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="l_name"
                                            placeholder="Enter Last Name"
                                            {...register("l_name", {
                                                required: "Last Name required",

                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.l_name,
                                                "is-valid": getValues("l_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="formMiddleName" className="mb-3">
                                <Form.Label className="emailtxt">
                                    Company
                                </Form.Label>
                                <div className="formfieldregister">
                                <Form.Control
                                        type="text"
                                        name="company"
                                        placeholder="Enter Company Name"
                                        {...register("company", {
                                            required: "company required",

                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.company,
                                            "is-valid": getValues("company"),
                                        })}

                                    />


                                </div>
                            </Form.Group>

                            <Form.Group controlId="formFirstName" className="mb-3">
                                <Form.Label className="emailtxt">Address*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    {...register("address", {
                                        required: "address required",

                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.address,
                                        "is-valid": getValues("address"),
                                    })}

                                />
                            </Form.Group>


                            <Row>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName" className="mb-3">
                                        <Form.Label className="emailtxt">Country*</Form.Label>
                                        <Controller
                                            name="country_id" // name of the field
                                            {...register("country_id", {
                                                required: "Select Country",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.country_id
                                                                ? "red"
                                                                : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={country}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption); // Update Controller's value
                                                        GetAllStates(selectedOption.value);
                                                        setValue("country_id", selectedOption);
                                                        setValue("state_id", null);
                                                        setValue("city_id", null);
                                                        setValue("pincode_id", null);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName" className="mb-3">
                                        <Form.Label className="emailtxt">State/Province*</Form.Label>
                                        <Controller
                                            name="state_id" // name of the field
                                            {...register("state_id", {
                                                required: "Select State",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.state_id ? "red" : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={states}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption); // Update Controller's value
                                                        GetAllCities(selectedOption.value);
                                                        setValue("state_id", selectedOption);
                                                        setValue("city_id", null);
                                                        setValue("pincode_id", null);
                                                    }}
                                                />
                                            )}
                                        />

                                        {errors.state_id && (
                                            <span className="text-danger">
                                                {errors.state_id.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">City*</Form.Label>
                                        <Controller
                                            name="city_id" // name of the field
                                            {...register("city_id", {
                                                required: "Select City",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.city_id ? "red" : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={cities}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption); // Update Controller's value
                                                        GetAllPincode(selectedOption.value);
                                                        setValue("city_id", selectedOption);
                                                        setValue("pincode_id", null);
                                                    }}
                                                />
                                            )}
                                        />

                                        {errors.city_id && (
                                            <span className="text-danger">
                                                {errors.city_id.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Pincode*</Form.Label>
                                        <Controller
                                            name="pincode_id" // name of the field
                                            {...register("pincode_id", {
                                                required: "Select pincode",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.pincode_id ? "red" : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={pincode}
                                                />
                                            )}
                                        />

                                        {errors.pincode_id && (
                                            <span className="text-danger">
                                                {errors.pincode_id.message}
                                            </span>
                                        )}
                                    </Form.Group>

                                </Col>
                               

                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName" className="mb-3">
                                        <Form.Label className="emailtxt">Contact No*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="contact_no"
                                            placeholder="Enter Contact No"
                                            {...register("contact_no", {
                                                required: "contact_no required",

                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.contact_no,
                                                "is-valid": getValues("contact_no"),
                                            })}
                                            maxLength={10}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}

                                        />
                                    </Form.Group>
                                </Col>

                            </Row>

                        </Form>

                    </div>
                </div>


                <div className="save-btn-div mt-5">
                    <button className="back-btn" onClick={handlePrevious}>Back</button>
                    <button className="continue-btn"  onClick={handleSubmit(onSubmit)}> Continue </button>
                    {/* <button className="continue-btn" onClick={handleNext}> Continue </button> */}
                </div>
            </section>
        </>
    )
}

export default Shipping_information