// import React, { useState,useEffect } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import { Link } from 'react-router-dom';
// import RewardDetails from '../../RewardDetails/RewardDetails';
// import {
//     postAddress,
//     getAddress,
//     deleteAddress,
//     getSingleAddress,getSingleShippingAddress,
//     postorder,postPayu,
//   } from "../../../utils/apis/orderProcess/orderProcess";
//   import {
//     AddToCart,
//     getCart,
//     deleteCart,
//   } from "../../../utils/apis/addToCart/addToCart";
// const Payment_info = ({ ShippingId,handleNext, handlePrevious, paymentMethod, setPaymentMethod ,setPaymentData,paymentData}) => {
//     const [selectedPayment, setSelectedPayment] = useState('');
//     const [isChecked, setIsChecked] = useState(false);
//     const [showRewardDetails, setShowRewardDetails] = useState(false);

//     const handlePaymentChange = (event) => {
//         setSelectedPayment(event.target.value);
//     };

//     const handleCheckboxChange = () => {
//         setIsChecked(!isChecked);
//     };

//     // const handleLearnMoreClick = () => {
//     //     setShowRewardDetails(true);
//     //     setCheckout(false);
//     // };
//     const [address, setadress] = useState({});
//     const GetEditShippingData = async () => {
//         const response = await getSingleShippingAddress(ShippingId);
//         setadress(response?.data);
//         // reset(response?.data);
//       };
//    console.log(address,"address aaaaaaaaaaaaaaaaaaa");


//     const [cart, setCart] = useState([]);
//     const cartGet = async () => {
//         const res = await getCart();
//         if (res?.success) {
//           setCart(res);
//         } else {
//           console.error("Error fetching data:", res?.error);
//         }
//       };
//       useEffect(() => {
//         cartGet();
//         GetEditShippingData();

//       }, [paymentMethod]);
//     // const [paymentData, setPaymentData] = useState(null);

//     const handlePayment = async (value) => {

//         console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkk");

//         // Set the selected payment method
//         setPaymentMethod(value);

//         try {
//             // Prepare payment data
//             const data = {
//                 firstname: 'kalyani',
//                 productinfo: 'address_id',
//                 amount: 2,
//                 email: 'kalyani.raghatate@profcyma.com',
//                 phone: '7798231121',
//                 // firstname: `${address?.f_name} ${address?.l_name}`, // Use template literals for clarity
//                 // productinfo: 'xyz', // Update this with actual product info if needed
//                 // amount: 1, // Replace with the actual amount you want to charge
//                 // email: 'kalyani.raghatate@profcyma.com', // Use the actual email
//                 // phone: '7798231121', // Use the actual phone number
//             };

//             // Call the postPayu function to initiate payment
//             const res = await postPayu(data);

//             // Log the response for debugging
//             console.log(res, "Payment Response");

//             // Check if the response contains the necessary payment data
//             // if (res && res.paymentData) {
//                 // Set payment data state
//                 setPaymentData(res); // Make sure you're accessing the correct field in the response
//             // } else {
//             //     console.error('Invalid payment response:', res);
//             // }
//         } catch (error) {
//             console.error('Payment initiation failed', error);
//         }
//     };


//     // if (paymentMethod == 'online') {
//     //     handlePayment(cart?.message?.total); // Pass the amount you want to use
//     // }
//     const handleMethod = async (value) => {
//         await setPaymentMethod(value);
//     }

//     return (
//         <>
//             <section className='stepp-inner-main'>
//                 <div className='row'>
//                     {
//                         !showRewardDetails && (
//                             <div className='col-lg-10 mx-auto'>
//                                 <Row className='step4check'>
//                                     <Col lg={6} md={6}>
//                                         <div className='bankTransfer'>
//                                             <Form.Check // prettier-ignore
//                                                 type="radio"
//                                                 name="method"
//                                                 label="Online"
//                                                 value="online"
//                                                 defaultChecked={paymentMethod === 'online'}
//                                                 onClick={() => handlePayment('online')}
//                                                 // onClick={() => handleMethod('online')}
//                                             />

//                                         </div>
//                                     </Col>
//                                     <Col lg={6} md={6}>
//                                         <div className='razorpaycheck'>
//                                             <Form.Check // prettier-ignore
//                                                 type="radio"
//                                                 name="method"
//                                                 label="Cash on delivery"
//                                                 value="cod"
//                                                 defaultChecked={paymentMethod === 'cod'}
//                                                 onClick={() => handleMethod('cod')}
//                                             />

//                                         </div>
//                                     </Col>
//                                 </Row>
//                                 {/* {selectedPayment === 'bankTransfer' && (
//                                     <div className='row'>
//                                         <Col lg={6}>
//                                             <div className='bankInput'>
//                                                 <Form.Check
//                                                     type='checkbox'
//                                                     label={
//                                                         <>
//                                                             <Form.Group className=" mb-2" controlId="exampleForm.ControlInput1">
//                                                                 <Form.Control type="email" placeholder="Transfer amount" />
//                                                             </Form.Group>
//                                                         </>
//                                                     }
//                                                     onChange={handleCheckboxChange}
//                                                 />
//                                                 {isChecked && (
//                                                     <div>
//                                                         <p className='step5Paymentlink'>
//                                                             <span>Each of 1 Point gets ₹1.00 discount. You need to earn more 1 point(s) to use this rule.</span>
//                                                             <span className='click-here' onClick={handleLearnMoreClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
//                                                                 Please click here to learn about it.
//                                                             </span>
//                                                         </p>
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </Col>
//                                     </div>
//                                 )} */}
//                                 {selectedPayment === 'razorpay' && (
//                                     <Row>
//                                         <Col xl={6} className='ms-auto'>
//                                             <div className='razorpayDetails'>
//                                                 <p>You will be redirected to Razorpay Checkout page when you place an order.</p>
//                                             </div>

//                                         </Col>
//                                     </Row>
//                                 )}
//                             </div>
//                         )
//                     }
//                 </div>
//                 {!showRewardDetails && (
//                     <div className="save-btn-div mt-5">
//                         <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
//                         <button type='button' className="continue-btn" onClick={handleNext}>Continue</button>
//                     </div>
//                 )}
//             </section>

//             {showRewardDetails && <RewardDetails />}
//         </>
//     );
// }

// export default Payment_info;


import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import RewardDetails from '../../RewardDetails/RewardDetails';
import {
    postAddress,
    getAddress,
    deleteAddress,
    getSingleAddress, getSingleShippingAddress,
    postorderOnline, postPayu,
    paymentMethod,
} from "../../../utils/apis/orderProcess/orderProcess";
import {
    AddToCart,
    getCart,
    deleteCart,getAllPrices,getAppsetup
} from "../../../utils/apis/addToCart/addToCart";
const Payment_info = ({
    ShippingId,
    address_id,
    giftdata,
    giftdataArray,
    handleNext,
    handlePrevious,
    paymentMethod,
    setPaymentMethod,
    setPaymentData,
    paymentData,setdataID,dataId }) => {
    const [selectedPayment, setSelectedPayment] = useState('');
    const [totalGiftInduasalPrice, setTotalGiftInduasalPrice] = useState(0);
    const [totalCustomePrice, setTotalcustomePrice] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [showRewardDetails, setShowRewardDetails] = useState(false);

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    // const handleLearnMoreClick = () => {
    //     setShowRewardDetails(true);
    //     setCheckout(false);
    // };


    const [address, setadress] = useState({});
    const GetEditShippingData = async () => {
        const response = await getSingleShippingAddress(ShippingId);
        setadress(response?.data);
        // reset(response?.data);
    };
    console.log(address, "address aaaaaaaaaaaaaaaaaaa");

    // const [dataId, setdata] = useState();
    const [cart, setCart] = useState([]);
    const cartGet = async () => {
        const res = await getCart();
        if (res?.success) {
            setCart(res);
        } else {
            console.error("Error fetching data:", res?.error);
        }
    };
    useEffect(() => {
        cartGet();
        GetEditShippingData();

    }, [paymentMethod]);

    console.log(dataId, "dataId dataId dataId");
    
    // const finalFunction = async (razor = null) => {
    //     console.log("jfffffffffffffffffffffff");

    //     const data = {
    //         shipping_address_id: ShippingId,
    //         billing_address_id: address_id,
    //         amount: cart?.message?.total,
    //         to: giftdata?.to,
    //         from: giftdata?.from,
    //         message: giftdata?.message,
    //         gift_status: giftdata?.gift_status,
    //         gift_details: giftdataArray,
    //         gift_price_total: totalGiftInduasalPrice,
    //         payment_mode: razor ? "online" : "cod",
    //     };

    //     if (razor) {
    //         data.payment_id = razor;
    //     }
    //     const res = await postorder(data);
    //     if (res?.success) {
    //         //   setOrderplace(true);
    //         //   setTimeout(() => {
    //         //     setOrderplace(false);
    //         //     window.location = "/home";
    //         //   }, 3000);
    //     }
    // };

    const [allPrice, setAllprice] = useState();
    const AllPriceGet = async () => {
        const res = await getAllPrices(giftdataArray,giftdata);
        if (res?.success) {
            setAllprice(res?.data);
        } else {
            console.error("Error fetching data:", res?.error);
        }
    };
    useEffect(() => {
        AllPriceGet();
       
    }, [paymentMethod]);

    const handleMethod = async (value) => {
        await setPaymentMethod(value);
        await AllPriceGet();
    }

    
 
    const handlePayment = async (value) => {
        // Set the selected payment method
        setPaymentMethod(value);
        console.log(allPrice.totalCalculations?.totalSellingPrice,"allPrice.totalCalculations?.totalSellingPrice allPrice.totalCalculations?.totalSellingPrice");
    
        try {
            // Prepare payment data
            const data = {
                firstname: `${address?.f_name} ${address?.l_name}`,
                shipping_address_id: ShippingId,
                billing_address_id: address_id,
                to: giftdata?.to,
                from: giftdata?.from,
                message: giftdata?.message,
                gift_status: giftdata?.gift_status,
                gift_details: giftdataArray,
                // gift_price_total: totalGiftInduasalPrice,
                payment_mode: value,
                totalSellingPrice:allPrice.totalCalculations?.totalSellingPrice,
                totalTax:allPrice.totalCalculations?.totalTax,
                totalMrp:allPrice.totalCalculations?.totalMrp,
                totalGiftPrice:allPrice.totalCalculations?.totalGiftPrice,
                totalCustomizePrice:allPrice.totalCalculations?.totalCustomizePrice,
                totalAmount:allPrice.totalCalculations?.totalAmount,
                payment_mode:paymentMethod,
                singleEntityCalculations:allPrice.singleEntityCalculations,        
            };
    
            // Conditionally add orderId to the data object if dataId exists
            if (dataId) {
                data.orderId = dataId; // Correct assignment syntax
            }
    
            // Call the postPayu function to initiate payment
            const response = await postorderOnline(data);
         
            if (response?.success) {
                console.log(response, "Payment Response");
                const paymentData = response?.data;  // Access the correct field in the response
                console.log(response?.data, "data data");
                // await setdataID(response?.data?.order?.id);
                await setPaymentData(paymentData);
                handleNext(); // Proceed to the next step
            } else {
                console.error('Invalid payment response:', response);
            }
        } catch (error) {
            console.error('Payment initiation failed', error);
        }
    };
    
    const [billingdata, setBillingData] = useState([]);
    const [shippingdata, setShippingData] = useState([]);
    const singleBillingAddress = async () => {
        const res = await getSingleAddress(address_id);
        if (res?.success) {
            setBillingData(res?.data);
        } else {
            console.error("Error fetching data:", res?.error);
        }
    };
    const singleshippingAddress = async () => {
        const res = await getSingleAddress(ShippingId);
        if (res?.success) {
            setShippingData(res?.data);
        } else {
            console.error("Error fetching data:", res?.error);
        }
    };

    useEffect(() => {
        cartGet();
        singleBillingAddress();
        singleshippingAddress();
    }, [giftdata, giftdataArray]);


    // useEffect(() => {
    //     let total;
    //     if (giftdataArray && giftdataArray?.length > 0) {
    //         total = giftdataArray
    //             ?.filter((cartDetail) => cartDetail?.gift_status === 1)
    //             .reduce((total, cartDetail) => {
    //                 const giftPrice = parseFloat(
    //                     cartDetail?.gift_price * cartDetail?.quantity
    //                 );
    //                 return total + giftPrice;
    //             }, 0);
    //     } else if (giftdata) {
           
    //         total = cart?.message?.giftprice;
    //     }
    //     const custometotal = cart?.data?.reduce((total, cartDetail) => {
    //         const giftPrice = parseFloat(
    //             cartDetail?.customize?.custom_price * cartDetail?.quantity
    //         );
    //         console.log(giftPrice, 'giftPrice ppppppppppppppppppppppppppppp');

    //         return total + giftPrice;
    //     }, 0);

    //     setTotalcustomePrice(custometotal);
    //     setTotalGiftInduasalPrice(total);
    // }, [giftdataArray, giftdata, cart]);

    // console.log(giftdataArray,'giftdataArray giftdataArray');
    
    return (
        <>
            <section className='stepp-inner-main'>
                <div className='row'>
                    {
                        !showRewardDetails && (
                            <div className='col-lg-10 mx-auto'>
                                <Row className='step4check'>
                                    <Col lg={6} md={6}>
                                        <div className='bankTransfer'>
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                name="method"
                                                label="Online"
                                                value="Online"
                                                defaultChecked={paymentMethod === 'Online'}
                                                // onClick={() => handlePayment('online')}
                                                onClick={() => handleMethod('Online')}
                                            />

                                        </div>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <div className='razorpaycheck'>
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                name="method"
                                                label="Cash on delivery"
                                                value="COD"
                                                defaultChecked={paymentMethod === 'COD'}
                                                onClick={() => handleMethod('COD')}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                {/* {selectedPayment === 'bankTransfer' && (
                                    <div className='row'>
                                        <Col lg={6}>
                                            <div className='bankInput'>
                                                <Form.Check
                                                    type='checkbox'
                                                    label={
                                                        <>
                                                            <Form.Group className=" mb-2" controlId="exampleForm.ControlInput1">
                                                                <Form.Control type="email" placeholder="Transfer amount" />
                                                            </Form.Group>
                                                        </>
                                                    }
                                                    onChange={handleCheckboxChange}
                                                />
                                                {isChecked && (
                                                    <div>
                                                        <p className='step5Paymentlink'>
                                                            <span>Each of 1 Point gets ₹1.00 discount. You need to earn more 1 point(s) to use this rule.</span>
                                                            <span className='click-here' onClick={handleLearnMoreClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                                                Please click here to learn about it.
                                                            </span>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </div>
                                )} */}
                                {selectedPayment === 'razorpay' && (
                                    <Row>
                                        <Col xl={6} className='ms-auto'>
                                            <div className='razorpayDetails'>
                                                <p>You will be redirected to Razorpay Checkout page when you place an order.</p>
                                            </div>

                                        </Col>
                                    </Row>
                                )}
                            </div>
                        )
                    }
                </div>
                {/* {!showRewardDetails && (
                    <div className="save-btn-div mt-5">
                        <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
                        if(paymentMethod==='online'){
                             <button type='button' className="continue-btn" onClick={() => handlePayment('online')}>Continue</button>
                        }else{
                            <button type='button' className="continue-btn" onClick={handleNext}>Continue</button>
                        }
                       
                    </div>
                )} */}

                {!showRewardDetails && (
                    <div className="save-btn-div mt-5">
                        <button type="button" className="back-btn" onClick={handlePrevious}>Back</button>
                        {paymentMethod === 'Online' ? (
                            <button type="button" className="continue-btn" onClick={() => handlePayment('Online')}>Continue</button>
                        ) : (
                            <button type="button" className="continue-btn" onClick={() => handleNext()}>Continue</button>
                        )}
                    </div>
                )}

            </section>

            {showRewardDetails && <RewardDetails />}
        </>
    );
}

export default Payment_info;
