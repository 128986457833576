import React, { useEffect, useRef, useState, useContext } from "react";
import "./GiftCustomization.css";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import Talktous from "../Common-Component/Offcanvas/Talktous/Talktous";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { CustomizationForm } from "../../utils/apis/form/form";
import { Material, allCategory, getAllProduct } from "../../utils/apis/master/Master";

const GiftCustomization = () => {
  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [customise, setcustomise] = useState(null);

  const [showSubmitModalState,setshowSubmitModalState] = useState(false)

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];


  const showSubmitModal = () =>{
    setshowSubmitModalState(true)
    setTimeout(() => {
      setshowSubmitModalState(false)
    }, 3000);
  }

  const uploader = Uploader({ apiKey: "free" });
  const uploadOptions = { multi: true };

  const customizationSteps = [
    { index: 1, title: "Choose", text: "A Customisable Gift" },
    { index: 2, title: "Upload", text: "Your Artwork" },
    { index: 3, title: "Order", text: "With Confidence" },
  ];

  const sampleGoods = [
    {
      title: "Customised Sample",
      description: "This product decorated with your logo or artwork",
      additionalInfo: [
        { heading: "Receive within", text: "2 to 48 hrs" },
        {
          heading: "Recommended If:",
          text:
            "you’re still ideating or want to share with your team for approval",
        },
      ],
      icon: "pdf.png",
    },
    {
      title: "Random Logo Sample",
      description: "This product decorated with a random company’s logo/design",
      additionalInfo: [
        { heading: "Receive within", text: "2 to 48 hrs" },
        {
          heading: "Recommended If:",
          text:
            "you’re still ideating or want to share with your team for approval",
        },
      ],
      icon: "molecule.png",
    },
    {
      title: "Customised Sample",
      description: "This product decorated with your logo or artwork",
      additionalInfo: [
        { heading: "Receive within", text: "2 to 48 hrs" },
        {
          heading: "Recommended If:",
          text:
            "you’re still ideating or want to share with your team for approval",
        },
      ],
      icon: "pdf.png",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const items = [
    { id: 1, text: "HOME", link: "/home" },
    { id: 1, text: "GIFT CUSTOMISATION" },
  ];

  const customiseClose = () => {
    setcustomise(true);
    setTimeout(() => {
      setcustomise(false);
      window.location.pathname = "/home";
    }, 3000);
  };
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    IMG_URL,
  } = useContext(Context);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    setShimmerLoader(true);

    const formData = new FormData();

    // formData.append("material_id", data?.material_id?.value);
    formData.append("product_id", data?.product_id?.value);
    formData.append("category_id", data?.category_id?.value);
    formData.append("requirement", data?.requirement);
    formData.append("company_name", data?.company_name);
    formData.append("image", data?.image[0]);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);
    if (selectedFiles.length > 0) {
      selectedFiles?.map((image, index) => {
        formData.append(`images[${index}]`, image);
      });
    }
    const res = await CustomizationForm(formData);
    if (res?.success) {
      customiseClose();
      // await Cookies.remove('customise_size');
      // navigate('/mycart');
    } else {
      if (res?.message?.gst_number) {
        setError("gst_number", { message: res?.message?.gst_number });
      }
    }
  };
  const [materialdata, setMaterialdata] = useState();
  const [productdata, setProductdata] = useState();
  const GetAllMaterial = async () => {
    const response = await allCategory(2);

    if (response?.success) {
      setMaterialdata(await Select2Data(response?.data, "category_id"));
    }
  };
  const GetAllProduct = async (id) => {
    const response = await getAllProduct(id);

    if (response?.success) {
      setProductdata(await Select2Data(response?.data, "product_id"));
    }
  };
  useEffect(() => {
    GetAllMaterial();
  }, []);
  const handleimageClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };
  console.log(getValues(), 'getValues');
  console.log(errors, 'eeeeeeeeeeeeee');


  return (
    <>
      <section className="GiftCustomization">
        <div className="imgMain">
          <img
            src={`${process.env.PUBLIC_URL}/assest/image/Careers/banner.png`}
            className="bannerImg"
            alt="Banner"
          />
          <div className="container">
            <div className="breadcrumsec">
              <div>
                <Breadcrumbs items={items} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="customizingWorking">
              <Row>
                <Col xl={6} md={6}>
                  <div>
                    <div className="customisehead">
                      <h3>
                        How <br />
                        <b>Customisation Works</b>
                      </h3>
                    </div>
                    <div className="text-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/assest/image/Careers/workingImg.png`}
                        className="workingImg"
                        alt="Working"
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={6}>
                  {customizationSteps.map((step, index) => (
                    <div className="custImgmain" key={index}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assest/image/Careers/customizeWork.png`}
                        className="rightSideimg"
                        alt={step.title}
                      />
                      <div className="row">
                        <div className="col-md-3">
                          <div className="custnumberTxt">
                            <h3>{step.index}</h3>
                          </div>
                        </div>

                        <div className="col-md-9">
                          <div className="custImgTxt">
                            <h3>{step.title}</h3>
                            <p>{step.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <div className="formMain">
              <div className="custoHead">
                <h3>Customisation Form</h3>
              </div>

              <Form >

                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "name required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.name,
                          "is-valid": getValues("name"),
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.name.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Controller
                      className="mb-3"
                      name="contact_no"
                      control={control}
                      rules={{
                        validate: (value) =>
                          value || getValues("email")
                            ? true
                            : "Mobile Number or Email is required",
                        pattern: {
                          value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                          message:
                            "Invalid number format. Please enter a valid number with an optional country code",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <Form.Label></Form.Label>
                          <PhoneInput
                            country={"in"}
                            {...field}
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                              "is-valid": getValues("contact_no"),
                            })}
                            onChange={(value) => field.onChange(value)}
                            type="text"
                            placeholder="Enter Mobile Number"
                            onKeyDown={(event) => {
                              if (!RegxExpression.phone.test(event.key)) {
                                if (event.key !== "Backspace") {
                                  event.preventDefault();
                                }
                              }
                            }}
                            maxLength={10}
                          />
                        </>
                      )}
                    />
                    {errors?.contact_no && (
                      <sup className="text-danger">
                        {errors?.contact_no?.message}
                      </sup>
                    )}
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          validate: (value) =>
                            value || getValues("contact_no")
                              ? true
                              : "Email or Mobile Number is required",
                          pattern: {
                            value: RegxExpression?.email,
                            message: "Invalid email address",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": getValues("email"),
                        })}
                      />
                    </Form.Group>

                  </Col>
                  <Col lg={6} md={6} className="mb-3">
                    <Controller
                      name="category_id"
                      control={control}
                      rules={{ required: "Select Product Category" }} // Validation rule
                      render={({ field }) => (
                        <>
                          <Form.Label>Select Product Category</Form.Label>
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.category_id ? "red" : baseStyles.borderColor,
                              }),
                            }}
                            {...field} // Spread field props here
                            options={materialdata} // Your select options
                            getOptionLabel={(option) => option.label} // How to display the label
                            getOptionValue={(option) => option.value} // How to get the value
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption); // Update Controller's value with the whole option object
                              GetAllProduct(selectedOption.value); // Trigger product fetching
                              setValue("category_id", selectedOption); // Set the selected option as value for the form
                              setValue("product_id", null); // Reset product_id
                            }}
                            value={field.value} // Ensure the selected value is passed back to the Select component
                          />
                        </>
                      )}
                    />
                    {errors.category_id && (
                      <span className="text-danger">
                        {errors.category_id.message}
                      </span>
                    )}
                  </Col>


                  <Col lg={6} md={6} className="mb-3">
                    <Controller
                      name="product_id" // name of the field
                      {...register("product_id", {
                        required: "Select Product",
                      })}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Form.Label>Select Product</Form.Label>
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.product_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={productdata}
                          />
                        </>
                      )}
                    />

                    {errors.product_id && (
                      <span className="text-danger">
                        {errors.product_id.message}
                      </span>
                    )}
                  </Col>

                  <Col lg={6} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        name="company_name"
                        placeholder="Enter Gift packing"
                        {...register("company_name", {
                          required: "gift packing required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.company_name,
                          "is-valid": getValues("company_name"),
                        })}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      {/* <Form.Label>Upload Customer Logo</Form.Label> Add a label to indicate the expected file */}
                      <Form.Label> Customer Logo</Form.Label>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          validate: async (value) => {
                            if (typeof value !== "string") {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              // const fileType = value[0].name?.split(".")[1];

                              // if (!fileTypes.includes(fileType)) {
                              //   return `Please upload a valid file format. (${fileTypes})`;
                              // }

                              // const sizes = await getDimension(value[0]);
                              // if (sizes.width !== 200 && sizes.height !== 260) {
                              //   return "Image width and height must be 200 px and 260 px";
                              // }

                              // const fileSize = Math.round(value[0].size / 1024);
                              // if (fileSize > 500) {
                              //   return "File size must be lower than 500kb";
                              // }
                            }
                          },
                        })}
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                      />
                    </Form.Group>
                  </Col>

                </Row>

                <div className="customCheck mb-3">
                  <Form.Label className="plus-btn me-5">
                    <div className="uploadDiv" onClick={handleimageClick}>
                      <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                      <p className="mb-1">Upload here</p>
                    </div>

                  </Form.Label>
                  <Form.Control
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    accept="image/"
                    style={{ display: "none" }}
                  />


                  <div className="image-holder">
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((file, index) => (
                        <div className="file-img-holder" key={index}>
                          <object
                            className="img-logo"
                            data={URL.createObjectURL(file)}
                            type={file.type}
                            width="59" // Set the width
                            height="58" // Set the height
                          />
                          <div
                            className="cross-overlay"
                            onClick={() => handleRemoveImage(index)}
                          >
                            <img
                              className="img-cross"
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/image/Careers/crossIcon.png"
                              }
                              alt={`Cross Icon ${index + 1}`}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="specialRequest">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="mb-0">
                      Add Special Requests
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="requirement"
                      placeholder="Enter Requirement"
                      {...register("requirement", {
                        required: "requirement is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.requirement,
                        "is-valid": getValues("requirement"),
                      })}
                    />
                  </Form.Group>
                </div>
              </Form>

              <div className="addtocartsec text-center">
                {/* <p>(*Add Special Requests and Submit)</p> */}
                {/* <Link to={"/mycart"}> */}
                <Button className="btn" onClick={handleSubmit(onSubmit)}>
                  Submit
                </Button>

                {/* </Link> */}
              </div>

            </div>
          </div>

          <div className="NeedHelp">
            <div className="container">
              <div className="Needhelpheading">
                <h3>
                  <strong>Need Help</strong> With Gifting Programs?
                </h3>
                <div className="Talktousbtn">
                  <Common_Button
                    text="TALK TO US"
                    className="talk-us"
                    onClick={handleShow}
                  />
                </div>
              </div>
            </div>
          </div>

          <Talktous
            show={show}
            handleClose={handleClose}
            placement="end"
            name="end"
            showSubmitModal={showSubmitModal}
          />

          <div className="Samplegoods">
            <div className="container">
              <div className="sampleHead">
                <h3>
                  Sample The Goods <br />
                  <b>Before Sending</b>
                </h3>
              </div>
              <div className="sampleRowMain">
                <Row>
                  {sampleGoods.map((good, index) => (
                    <Col xl={4} lg={4} md={6} key={index}>
                      <Card className="samplecard">
                        <div className="sampleHeadinginfo">
                          <h3>{good.title}</h3>
                          <p>{good.description}</p>
                        </div>
                        <div className="downside">
                          {good.additionalInfo.map((info, idx) => (
                            <div className="d-flex cardtxt" key={idx}>
                              <img
                                src={`${process.env.PUBLIC_URL}/assest/image/Careers/clock.png`}
                                className="clockImg"
                                alt="Clock"
                              />
                              <div>
                                <h3>{info.heading}</h3>
                                <p>{info.text}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="iconCircle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assest/image/Careers/${good.icon}`}
                            alt={good.title}
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <SuccessfullyModal
          show={customise}
          onHide={() => setcustomise(false)}
          text={"Form Submited Successfully"}
        />

        <SuccessfullyModal
          show={showSubmitModalState}
          text={"Our team will call you soon"}
        />
      </section>
    </>
  );
};

export default GiftCustomization;
