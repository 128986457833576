import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import Entireorder from './entireorder';
import Individualorder from './individualorder';
const Shipping_method = ({ handleNext, totalGiftInduasalPrice,giftOption,setGiftOption,handlePrevious, setGiftData,giftdata,giftdataArray,setGiftDataarray ,setGiftOptionsIndividualItems,giftOptionsIndividualItems,setGiftOptionsEntireOrder,giftOptionsEntireOrder,setCurrentStep,currentStep}) => {
    // const [currentStep, setCurrentStep] = useState('shippingMethod'); 
    // const [giftOptionsEntireOrder, setGiftOptionsEntireOrder] = useState(false);
    // const [giftOptionsIndividualItems, setGiftOptionsIndividualItems] = useState(false);

    // const [giftOption, setGiftOption] = useState(false);

    // const handleCheckboxChange = (e, type) => {
    //     setGiftOption(true)
    //     // const isChecked = e.target.checked;
    //     // if (type === 'entireOrder') {
    //     //     setGiftOptionsEntireOrder(isChecked);
    //     // } else if (type === 'individualItems') {
    //     //     setGiftOptionsIndividualItems(isChecked);
    //     // }
    // };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setGiftOption(isChecked);
        if (!isChecked) {
            setGiftOptionsEntireOrder(false);
            setGiftOptionsIndividualItems(false);
            setSelectedOption('');
        }
    };

    
    const handleContinue = () => {
        if (giftOption) {
            if (giftOptionsEntireOrder) {
          
                setCurrentStep('entireOrderDesign');
            } else {
      
                setCurrentStep('individualItemsDesign');
            }
        } else {
            handleNext();
        }
    };

    const [selectedOption, setSelectedOption] = useState('');

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        if (value === 'entireOrder') {
            setGiftOptionsEntireOrder(true);
            setGiftOptionsIndividualItems(false);
        } else if (value === 'individualItems') {
            setGiftOptionsIndividualItems(true);
            setGiftOptionsEntireOrder(false);
        }
    };

    
    return (
        <>
            {currentStep === 'shippingMethod' && (
                <section className='stepp-inner-main'>
                    <div className='ShippingMethod'>
                        {/* <h3>Flat Rate : ₹ 300</h3> */}

                        <Form.Check
                            type="checkbox"
                            checked={giftOption}
                            // onChange={(e) => handleCheckboxChange(e)}
                            onChange={handleCheckboxChange}
                            label="DO YOU HAVE ANY GIFT ITEMS IN YOUR ORDER?"
                        />

                        {giftOption && (
                            <>
                                {/* <div className='shippingMethod'>
                                    <div className='giftShipping'>
                                        <h3>GIFT OPTIONS FOR THE ENTIRE ORDER</h3>
                                        <Form.Check
                                            type="radio"
                                            
                                            label="Add gift options for the Entire Order"
                                            onChange={(e) => handleCheckboxChange(e, 'entireOrder')}
                                        />
                                    </div>

                                    <div className='giftShipping'>
                                        <h3>GIFT OPTIONS FOR INDIVIDUAL ITEMS</h3>
                                        <Form.Check
                                           type="radio"
                                             
                                            label="Add gift options for Individual Items"
                                            onChange={(e) => handleCheckboxChange(e, 'individualItems')}
                                        />
                                    </div>
                                </div>  */}

                                <div className='shippingMethod'>
                                    <div className='giftShipping'>
                                        <h3>GIFT OPTIONS FOR THE ENTIRE ORDER</h3>
                                        <Form.Check
                                            type="radio"
                                            id="entireOrder"
                                            name="giftOptions"
                                            value="entireOrder"
                                            label="Add gift options for the Entire Order"
                                            // checked={selectedOption === 'entireOrder'}
                                            checked={giftOptionsEntireOrder}
                                            onChange={handleRadioChange}
                                           
                                        />
                                    </div>

                                    <div className='giftShipping'>
                                        <h3>GIFT OPTIONS FOR INDIVIDUAL ITEMS</h3>
                                        <Form.Check
                                            type="radio"
                                            id="individualItems"
                                            name="giftOptions"
                                            value="individualItems"
                                            label="Add gift options for Individual Items"
                                            checked={giftOptionsIndividualItems}
                                            // checked={selectedOption === 'individualItems'}
                                            onChange={handleRadioChange}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="save-btn-div mt-5">
                        <button className="back-btn" onClick={handlePrevious}> Back</button>
                        <button className="continue-btn" onClick={handleContinue}> Continue </button>
                    </div>
                </section>
            )}

            {currentStep === 'entireOrderDesign' && (
                // <section className='stepp-inner-main'>
                //     <Row>
                //         <Col xl={8}>
                //             <div className='shippingMethod'>
                //                 <div className='giftShipping'>
                //                     <h3>GIFT OPTIONS FOR THE ENTIRE ORDER</h3>
                //                     <Form.Check
                //                         type="checkbox"
                //                         label="Add gift options for the Entire Order"
                //                         onChange={(e) => handleCheckboxChange(e, 'entireOrder')}
                //                     />

                //                     <Form className='mt-3'>
                //                         <Row className='shippindmethodform'>
                //                             <Col xl={6} md={6}>
                //                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                //                                     <Form.Label>From</Form.Label>
                //                                     <Form.Control type="text" className='shippinfform' />
                //                                 </Form.Group>
                //                             </Col>
                //                             <Col xl={6} md={6}>
                //                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                //                                     <Form.Label>To</Form.Label>
                //                                     <Form.Control type="text" className='shippinfform' />
                //                                 </Form.Group>
                //                             </Col>

                //                             <Col xl={12}>
                //                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                //                                     <Form.Label>Message</Form.Label>
                //                                     <Form.Control type="text" placeholder='Max. 50 characters' className='shippinfform' />
                //                                 </Form.Group>
                //                             </Col>
                //                         </Row>
                //                     </Form>
                //                 </div>
                //             </div>
                //         </Col>
                //     </Row>
                //     <div className="save-btn-div mt-5">
                //         <button className="back-btn" onClick={() => setCurrentStep('shippingMethod')}> Back </button>
                //         <button className="continue-btn" onClick={handleNext}> Continue </button>
                //     </div>
                // </section>
                <Entireorder   totalGiftInduasalPrice={totalGiftInduasalPrice} setCurrentStep={setCurrentStep} handleNext={handleNext} setGiftData={setGiftData} giftdata={giftdata} setGiftDataarray={setGiftDataarray} giftdataArray={giftdataArray}/>
            )}

            {currentStep === 'individualItemsDesign' && (
               <Individualorder  setCurrentStep={setCurrentStep} handleNext={handleNext} setGiftData={setGiftData} giftdata={giftdata} setGiftDataarray={setGiftDataarray} giftdataArray={giftdataArray}/>
            )}
        </>
    );
};

export default Shipping_method;
