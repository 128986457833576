import React, { useState, useEffect, useContext } from 'react';
import { Card } from 'react-bootstrap';
import './ProductCard.css';
import { Link } from 'react-router-dom';
import Heart_Component from "../Heart_Component/Heart_Component";
import ReviewModal from '../Commonmodal/ReviewModal/ReviewModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "aos/dist/aos.css";
import { Rating } from "react-simple-star-rating";
import { Context } from "../../../utils/context";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
    allProductReviewGet,
    productReviewGet,
    productReviewPost,
} from "../../../utils/apis/Product/Product";
const ProductCard = ({ order, custom, wishlistData, orderGet, orderStatus, showRating, handleRating, rating, review }) => {
    const [modalShow, setModalShow] = useState(false);
    const [rateShow, setrateShow] = useState(false);
    const getStatusColor = () => {
        switch (orderStatus?.order_status?.order_status) {
            case 'Ordered':
                return '#234C9E';
            case 'Delivered':
                return '#00940A';
            case 'Returned':
                return '#D60101';
            case 'Shipped':
                return '#234C9E';
            default:
                return '#000';
        }
    };




    const { IMG_URL, formatDate } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        defaultValues: {
            ratings: 1,
        },
    });
    const [ratings, setRatings] = useState([1, 0, 0, 0, 0]);
    const handleStarClick = (starIndex) => {
        const newRatings = [...ratings];
        var rate = 1;
        for (let i = 0; i < newRatings.length; i++) {
            newRatings[i] = i < starIndex ? 1 : 0;
        }
        newRatings?.map((value, index) => {
            if (value == 1) {
                rate = index + 1;
            }
        });

        setRatings(newRatings);
        getValues("ratings")
        setValue("ratings", rate);
    };



    const renderStars = () => {
        return ratings.map((rating, index) => (
            <FontAwesomeIcon
                key={index}
                icon={rating === 1 ? solidStar : regularStar}
                className="star-icon me-1"
                onClick={() => handleStarClick(index + 1)}
            />
        ));
    };



    const [reviewData, setreviewData] = useState()
    const reviewGet = async () => {
        const res = await productReviewGet(orderStatus?.product?.id);
        setreviewData(res?.data);
        if (res?.success) {
            setreviewData(res);


        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    useEffect(() => {
        reviewGet();
    }, []);

    console.log(custom, "custom customorder");


    return (

        <section className='Myorders'>
            {order ? (
                <Card className="order-card">
                    <Heart_Component product_id={order?.id} variant_id={order?.product_variants?.length > 0 ? order.product_variants[0]?.id : undefined} />

                    {custom ? (
                        <Link to={`/productDetails/${order?.id}?type=Customize Product`} className='productImg'>
                            <div className='hfsuihguyg'>

                                <Card.Img variant="top" src={order?.product_variants?.[0]?.first_image ? `${IMG_URL}${order?.product_variants[0]?.first_image}` : 'default_image_url'} />
                                {/* {order?.product_variants?.[1]?.first_image && (
                                <Card.Img
                                    variant="top"
                                    src={`${IMG_URL}${order.product_variants[1].first_image}`}
                                />
                            )} */}
                                {order?.product_variants?.[1]?.first_image ? (
                                    <Card.Img variant="top" src={order?.product_variants?.[1]?.first_image ? `${IMG_URL}${order?.product_variants[1]?.first_image}` : 'default_image_url'} />

                                ) : (
                                    <Card.Img variant="top" src={order?.product_variants?.[0]?.first_image ? `${IMG_URL}${order?.product_variants[0]?.first_image}` : 'default_image_url'} />

                                )}
                            </div>

                        </Link>
                    ) : (
                        <Link to={`/productDetails/${order?.id}?subcategorytype=${order?.sub_category?.name}`} className='productImg'>
                            <div className='hfsuihguyg'>

                                <Card.Img variant="top" src={order?.product_variants?.[0]?.first_image ? `${IMG_URL}${order?.product_variants[0]?.first_image}` : 'default_image_url'} />
                                {/* {order?.product_variants?.[1]?.first_image && (
                                <Card.Img
                                    variant="top"
                                    src={`${IMG_URL}${order.product_variants[1].first_image}`}
                                />
                            )} */}
                                {order?.product_variants?.[1]?.first_image ? (
                                    <Card.Img variant="top" src={order?.product_variants?.[1]?.first_image ? `${IMG_URL}${order?.product_variants[1]?.first_image}` : 'default_image_url'} />

                                ) : (
                                    <Card.Img variant="top" src={order?.product_variants?.[0]?.first_image ? `${IMG_URL}${order?.product_variants[0]?.first_image}` : 'default_image_url'} />

                                )}
                            </div>

                        </Link>
                    )}


                    <div className='orderedstatusMain'>
                        <Link to={"/returnOrder"}>
                            <div className='orderedstatus' style={{ color: getStatusColor() }}>{order.status}</div>
                        </Link>
                    </div>
                    <Card.Body>
                        <Card.Title>{order.name}</Card.Title>
                        <Card.Text className='mb-0'>
                            <div className='productPrice'>
                                {Math?.ceil(order?.product_variants?.[0]?.s_price
                                    ? Math.floor(order.product_variants[0].s_price)
                                    : 'Default Name')}

                            </div>
                        </Card.Text>
                        {/* <div className='Orderdate'>{order?.product_variants?.length > 0 ? formatDate(order.product_variants[0].date) : 'Default Name'}</div> */}

                        {
                            order.status === "Delivered" && (
                                <>
                                    <div className={`ratethisproduct ${rateShow ? "showreview" : "removeReview"}`} onClick={() => setrateShow(true)}>
                                        <div className='starImg_txt'>
                                            <span> Rate this Product</span>
                                            <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/gropStars.png"} className='groupStars' /></span>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            rateShow && (
                                <>
                                    {/* <Rating
                                        onClick={handleRating}
                                        initialValue={rating}
                                        fillColor="#DF4223"
                                        className="react-simple-star-rating start"
                                        SVGclassName="star-svg"
                                        size={20}
                                    /> */}
                                    {renderStars}
                                    <div className='addreview  mt-1' onClick={() => setModalShow(true)}>Add Review</div>


                                </>

                            )
                        }
                    </Card.Body>
                </Card>
            ) : wishlistData ? (
                <Card className="order-card"
                    data-aos-duration="3000">
                    <Heart_Component product_id={wishlistData?.product?.id} variant_id={wishlistData?.product_variant?.id} />

                    <Link to={`/productDetails/${wishlistData?.product?.id}?type=Wishlists`} className='productImg'>
                        {/* <Link to={`/returnOrder/${orderStatus?.id}`} className='productImg'> */}
                        <div className='hfsuihguyg'>
                            <Card.Img variant="top" src={wishlistData?.product_variant?.product_images?.[0]?.image ? `${IMG_URL}${wishlistData?.product_variant?.product_images[0]?.image}` : 'default_image_url'} />
                            {orderStatus?.product_variant?.product_images?.[1]?.image ? (
                                <Card.Img variant="top" src={wishlistData?.product_variant?.product_images?.[1]?.image ? `${IMG_URL}${wishlistData?.product_variant?.product_images[1]?.image}` : 'default_image_url'} />

                            ) : (
                                <Card.Img variant="top" src={wishlistData?.product_variant?.product_images?.[0]?.image ? `${IMG_URL}${wishlistData?.product_variant?.product_images[0]?.image}` : 'default_image_url'} />

                            )}

                        </div>

                    </Link>


                    <div className='orderedstatusMain'>
                        {/* <Link to={"/returnOrder"}> */}
                        <Link to={`/productDetails/${wishlistData?.product?.id}`}>
                            <div className='orderedstatus' style={{ color: getStatusColor() }}>{wishlistData?.order_status?.order_status}</div>
                        </Link>
                    </div>
                    <Card.Body>
                        <Card.Title>{wishlistData?.product?.name}</Card.Title>
                        <Card.Text className='mb-0'>
                            <div className='productPrice'>
                                ₹{Math.ceil(wishlistData?.product_variant?.s_price || 'Default Name')}
                            </div>
                        </Card.Text>
                        {/* <div className='Orderdate'>{formatDate(orderStatus?.updatedAt ?? '')}</div> */}

                        {/* {
                        orderStatus?.order_status?.id == 4 && (
                            <>
                                <div className={`ratethisproduct ${rateShow ? "showreview" : "removeReview"}`} onClick={() => setrateShow(true)}>
                                    <div className='starImg_txt'>
                                        <span> Rate this Product</span>
                                        <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/gropStars.png"} className='groupStars' /></span>
                                    </div>
                                </div>
                            </>
                        )
                    } */}
                        {
                            orderStatus?.order_status?.id == 4 && (
                                <>
                                    {reviewData?.hasReviewed ? (

                                        <span>Review Added</span>
                                    ) : (

                                        <div className={`ratethisproduct ${rateShow ? "showreview" : "removeReview"}`} onClick={() => setrateShow(true)}>
                                            <div className='starImg_txt'>
                                                <span>Rated this Product</span>
                                                <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/gropStars.png"} className='groupStars' /></span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                        }



                    </Card.Body>
                </Card>
            ) : (
                <Card className="order-card"
                    data-aos-duration="3000">
                    <Heart_Component product_id={orderStatus?.product?.id} variant_id={orderStatus?.product_variant?.id} />

                    {/* <Link to={"/productDetails"} className='productImg'> */}
                    <Link to={`/returnOrder/${orderStatus?.id}`} className='productImg'>
                        <div className='hfsuihguyg'>
                            <Card.Img variant="top" src={orderStatus?.product_variant?.product_images?.[0]?.image ? `${IMG_URL}${orderStatus?.product_variant?.product_images[0]?.image}` : 'default_image_url'} />
                            {orderStatus?.product_variant?.product_images?.[1]?.image ? (
                                <Card.Img variant="top" src={orderStatus?.product_variant?.product_images?.[1]?.image ? `${IMG_URL}${orderStatus?.product_variant?.product_images[1]?.image}` : 'default_image_url'} />

                            ) : (
                                <Card.Img variant="top" src={orderStatus?.product_variant?.product_images?.[0]?.image ? `${IMG_URL}${orderStatus?.product_variant?.product_images[0]?.image}` : 'default_image_url'} />

                            )}

                        </div>

                    </Link>


                    <div className='orderedstatusMain'>
                        {/* <Link to={"/returnOrder"}> */}
                        <Link to={`/returnOrder/${orderStatus?.id}`}>
                            <div className='orderedstatus' style={{ color: getStatusColor() }}>{orderStatus?.order_status?.order_status}</div>
                        </Link>
                    </div>
                    <Card.Body>
                        <Card.Title>{orderStatus?.product?.name}</Card.Title>
                        <Card.Text className='mb-0'>
                            <div className='productPrice'>
                                ₹{Math.ceil(orderStatus?.total_amount || 'Default Name')}
                            </div>
                        </Card.Text>
                        <div className='Orderdate'>{formatDate(orderStatus?.updatedAt ?? '')}</div>

                        {/* {
                            orderStatus?.order_status?.id == 4 && (
                                <>
                                    <div className={`ratethisproduct ${rateShow ? "showreview" : "removeReview"}`} onClick={() => setrateShow(true)}>
                                        <div className='starImg_txt'>
                                            <span> Rate this Product</span>
                                            <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/gropStars.png"} className='groupStars' /></span>
                                        </div>
                                    </div>
                                </>
                            )
                        } */}
                        {
                            orderStatus?.order_status?.id == 4 && (
                                <>
                                    {reviewData?.hasReviewed ? (

                                        <span>Review Added</span>
                                    ) : (

                                        <div className={`ratethisproduct ${rateShow ? "showreview" : "removeReview"}`} onClick={() => setrateShow(true)}>
                                            <div className='starImg_txt'>
                                                <span>Rated this Product</span>
                                                <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/gropStars.png"} className='groupStars' /></span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                        }


                        {
                            rateShow && (
                                <>
                                    {/* <Rating
                                        onClick={handleRating}
                                        initialValue={rating}
                                        fillColor="#DF4223"
                                        className="react-simple-star-rating start"
                                        SVGclassName="star-svg"
                                        size={20}
                                    /> */}
                                    {renderStars()}
                                    <div className='addreview  mt-1' onClick={() => setModalShow(true)}>{review}</div>


                                </>

                            )
                        }
                    </Card.Body>
                </Card>
            )}



            <ReviewModal reviewGet={reviewGet} order_id={orderStatus?.id} product_id={orderStatus?.product?.id} rating={getValues("ratings")} show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
        </section >
    );
};

export default ProductCard;
