import React, { useState, useEffect, useContext } from "react";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";

import { RegxExpression } from "../../../../utils/common";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  selleraddress,
  getselleraddress,
} from "../../../../utils/apis/sellerForm/Sellerform";
import {
  Country,
  State,
  City,
  Pincode,
} from "../../../../utils/apis/master/Master";
import { loginCart } from "../../../../utils/apis/addToCart/addToCart";
import SuccessfullyModal from "../../../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import Cookies_popup_modal from "../../../Common-Component/Commonmodal/Cookies_popup_modal/Cookies_popup_modal";
library.add(fas);

const Address = ({
  handleNext,
  handlePrevious,
  setAddressSeAddNewaddresslect,
  addressSelect,
}) => {
  const [isSaved, setIsSaved] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [value, setValue] = useState();

  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showSuccess, setShowsuccess] = useState(false);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    SellerURL,
    isCookieAccepted,
    setIsCookieAccepted,
  } = useContext(Context);
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const [isChecked, setIsChecked] = useState(false);

  const customiseClose = () => {
    setShowsuccess(true);
    setTimeout(() => {
      setShowsuccess(false);
      window.location.pathname = "/home";
    }, 3000);
  };
  const [sellerId, setSellerId] = useState("");
  const [showCookiesModal, setShowCookiesModal] = useState(true);
  const onSubmit = async (data) => {
    {
      !isCookieAccepted && (
        <Cookies_popup_modal
          isCookieAccepted={isCookieAccepted}
          setIsCookieAccepted={setIsCookieAccepted}
          show={showCookiesModal}
          onHide={() => setShowCookiesModal(false)}
          onAcceptCookies={handleAcceptCookies}
        />
      );
    }

    setShimmerLoader(true);

    const formData = new FormData();

    formData.append("personal_detail_id", sellerId);
    formData.append("country_id", data?.country_id?.value);
    formData.append("state_id", data?.state_id.value);
    formData.append("city_id", data?.city_id.value);
    formData.append("street_address", data?.street_address);
    formData.append("pincode", data?.pincode);
    formData.append("contact_no", data?.contact_no);
    formData.append("term_condition_status", isChecked);

    const res = await selleraddress(formData);
    if (res?.success) {
      await Cookies.set("coinz_kart_security", res?.data?.token);
      Cookies.remove("seller_id");
      await setSignin(true);
      await loginCart();
      customiseClose();
      // setTimeout(() => {
      //     setShimmerLoader(false);
      //     navigate(-1);
      // }, 1000);
    } else {
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };

  const getEditData = async () => {
    const res = await getselleraddress(sellerId);
    if (res?.success) {
      reset(res?.data);
    }
  };
  useEffect(() => {
    getEditData();
  }, [sellerId]);
  useEffect(() => {
    setSellerId(Cookies.get("seller_id"));
  }, []);

  const GetAllCountries = async () => {
    const response = await Country();

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await State(id);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await City(id);

    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCountries();
    GetAllStates();
    GetAllCities();
  }, []);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleAcceptCookies = () => {
    setShowCookiesModal(false);
  };

  return (
    <>
      <section className="stepp-inner-main">
        <Row>
          <Col lg={8} className="mx-auto">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Street Address*</Form.Label>
                <Form.Control
                  type="text"
                  name="street_address"
                  placeholder="Street Address"
                  {...register("street_address", {
                    required: "street_address required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.street_address,
                    "is-valid": getValues("street_address"),
                  })}
                />
              </Form.Group>

              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Country*</Form.Label>
                    <Controller
                      name="country_id" // name of the field
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption); // Update Controller's value
                            GetAllStates(selectedOption.value);
                            setValue("country_id", selectedOption);
                            setValue("state_id", null);
                            setValue("city_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>State / Province*</Form.Label>
                    <Controller
                      name="state_id" // name of the field
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={states}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption); // Update Controller's value
                            GetAllCities(selectedOption.value);
                            setValue("state_id", selectedOption);
                            setValue("city_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>City*</Form.Label>
                    <Controller
                      name="city_id" // name of the field
                      {...register("city_id", {
                        required: "Select City",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={cities}
                        />
                      )}
                    />

                    {errors.city_id && (
                      <span className="text-danger">
                        {errors.city_id.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>ZIP / Postal Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="pincode"
                      // value={formData.name}
                      // onChange={handleChange}
                      placeholder="Pincode"
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      {...register("pincode", {
                        required: "Enter Pincode",
                        pattern: {
                          value: /^\d{6}$/, // Regex pattern for 6 digits
                          message: "Pincode must be a 6-digit number",
                        },
                      })}
                    // id="inputEmail3"
                    // className="input-mandatory"
                    />
                    {errors.pincode && (
                      <span className="text-danger">
                        {errors.pincode.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Contact*</Form.Label>
                    <Controller
                      name="contact_no"
                      control={control}
                      rules={{
                        required: "Field is required",
                        minLength: {
                          value: 10,
                          message: "Number should be at least 10 characters",
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          country="in"
                          {...field}
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                            "is-valid": getValues("contact_no"),
                          })}
                          onChange={(value) => field.onChange(value)}
                          type="text"
                          placeholder="Enter Mobile Number"
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                      )}
                    />
                    {errors?.contact_no && (
                      <sup className="text-danger">
                        {errors?.contact_no?.message}
                      </sup>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <div className="save-btn-div">
          <Common_Button
            onClick={handlePrevious}
            type="button"
            text={"Back"}
            className="back-btn"
          />
          {/* <Link to={"/login"}> */}
          <Common_Button
            onClick={handleSubmit(onSubmit)}
            text={"Create Account"}
            className="continue-btn"
          />

          {/* </Link> */}
        </div>

        <div className="signup-link">
          <p>
            <input
              type="checkbox"
              {...register("isChecked", {
                required: "Term And Conditions Required",
              })}
              className={classNames("", {
                "is-invalid": errors?.isChecked,
              })}
            />

            {/* <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            /> */}
            By signing up, I agree to the
            <Link to={"/terms-condition"} style={{ color: "#234C9E" }}>
              Terms and Conditions
            </Link>
          </p>
          {errors?.isChecked && (
            <sup className="text-danger">
              {errors?.isChecked?.message}
            </sup>
          )}
        </div>
        <div className="social-login pb-5">
          <p>You can also login by using your</p>
          <div className="social-icons">
            <img
              src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"}
              alt="Google"
            />
            <img
              src={process.env.PUBLIC_URL + "/assest/image/login/google.png"}
              alt="Google"
            />
            <img
              src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"}
              alt="Google"
            />
          </div>
        </div>
      </section>
      {!isCookieAccepted && (
        <Cookies_popup_modal
          isCookieAccepted={isCookieAccepted}
          setIsCookieAccepted={setIsCookieAccepted}
          show={showCookiesModal}
          onHide={() => setShowCookiesModal(false)}
          onAcceptCookies={handleAcceptCookies}
        />
      )}

      <SuccessfullyModal
        show={showSuccess}
        onHide={() => setShowsuccess(false)}
        text={"Your Request has been send successfully"}
      />
    </>
  );
};

export default Address;
