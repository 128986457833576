import React, { useState, useEffect, useContext } from "react";
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import Entireorder from './entireorder';
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import { postAddress, getAddress } from "../../../utils/apis/orderProcess/orderProcess";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City, Pincode } from "../../../utils/apis/master/Master";
import PasswordChecklist from "react-password-checklist";
import { AddToCart, getCart, getAppsetup, deleteCart } from "../../../utils/apis/addToCart/addToCart";

const Shipping_method = ({ handleNext, handlePrevious, giftdata, setGiftData, setGiftDataarray, giftdataArray, setGiftOptionsIndividualItems, giftOptionsIndividualItems, setGiftOptionsEntireOrder, giftOptionsEntireOrder, setCurrentStep, currentStep }) => {


    const { IMG_URL, signin, setCartCount, setTrigger, updateCartCount } = useContext(Context);


    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger, setValue,
    } = useForm();



    const [cart, setCart] = useState([]);
    const cartGet = async () => {
        const res = await getCart();
        if (res?.success) {
            setCart(res?.data);
        } else {
            console.error('Error fetching data:', res?.error);
        }
    };

    useEffect(() => {
        cartGet();
    }, [giftdataArray.length < 0]);

    useEffect(() => {
        if (giftdataArray.length > 0) {

            setGiftDataarray(giftdataArray?.map((val) => ({
                image: val?.image,
                name: val?.name,
                quantity: val?.quantity,
                gift_price: val?.gift_price,
                card_id: val?.card_id,
                from: val?.from,
                to: val?.to,
                message: val?.message,
                gift_status: val?.gift_status,
            })));
        } else {

            setGiftDataarray(cart?.map((val) => ({

                image: val?.product_variant?.product_images[0]?.image,
                name: val?.product?.name,
                gift_price: val?.product?.product_detail?.gift_price,
                s_price: val?.product_variant?.s_price,
                quantity: val?.quantity,
                card_id: val?.id,
                from: '',
                to: '',
                message: '',
                gift_status: '',
            })));

        }

    }, [cart, giftdataArray.length > 0]);





    const handleInputChange = (index, field, value) => {
        const updatedFormData = giftdataArray.map((item, idx) =>
            idx === index ? { ...item, [field]: value } : item
        );
        setGiftDataarray(updatedFormData);
    };

    const [validationError, setValidationError] = useState(false);
    const handleCheckboxdataChange = (index) => {
        const updatedFormData = giftdataArray.map((item, idx) =>
            idx === index ? { ...item, gift_status: item.gift_status === 1 ? 0 : 1 } : item
        );
        setGiftDataarray(updatedFormData);
        setGiftData();
        setValidationError(false);
    };

    const onSubmit = (data) => {
        const isFieldFilled = giftdataArray.some((item) => item.from && item.to && item.message && item.gift_status);
        if (!isFieldFilled) {
            setError('giftItems', {
                type: 'manual',
                message: 'At least one gift item field must be filled.',
            });
            return;
        }
        handleNext();

    };



    const [appSetup, setAppSetup] = useState([]);
    const GetAppSetup = async () => {
        const res = await getAppsetup();
        if (res?.success) {
            setAppSetup(res?.data);
        } else {
            console.error('Error fetching data:', res?.error);
        }
    };

    useEffect(() => {
        GetAppSetup();
    }, []);
    return (
        <>
            <section className='stepp-inner-main'>
                <Row>
                    <Form className='mt-3'>
                        <Col xl={8} className='mx-auto'>
                            <div className='secondscreen'>
                                {/* <h3 className='flatrate'>Flat Rate : ₹ 300</h3> */}

                                <Form.Check
                                    defaultChecked
                                    type="checkbox"
                                    label="DO YOU HAVE ANY GIFT ITEMS IN YOUR ORDER?"
                                    {...register('giftItems', { required: 'This checkbox is required' })}
                                />
                                {errors?.giftItems && (
                                    <sup className="text-danger">
                                        {errors?.giftItems?.message}
                                    </sup>
                                )}

                            </div>

                            {giftdataArray?.map((val, index) =>
                                <div className='shippingMethodone' key={index}>
                                    <div className='giftShipping'>
                                        <h3>GIFT OPTIONS FOR INDIVIDUAL ITEMS</h3>
                                        <h3>
                                            Flat Rate: ₹{' '}
                                            {
                                                (val?.s_price > appSetup.gift_wrap_min_amount
                                                    ? Number(appSetup.gift_warp_above_amount || 0)
                                                    : Number(appSetup.gift_warp_below_amount || 0)
                                                ) * Number(val?.quantity)
                                            } </h3>
                                            <Form.Check
                                                type="checkbox"
                                                // label="Add gift options for Individual Items"
                                                label={val?.name}
                                                checked={val.gift_status === 1}
                                                onChange={() => handleCheckboxdataChange(index)}
                                            />
                                            <div>
                                                <div className='prod-Img-main text-start'>
                                                    <img src={IMG_URL + val?.image} className='prodImg1' alt="Product" />
                                                </div>
                                                <p></p>
                                            </div>
                                            {/* <Form className='mt-3'> */}
                                            <Row className='shippindmethodform'>
                                                <Col xl={6} md={6}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>From</Form.Label>
                                                        <Form.Control
                                                            className={`chartinfo mb-0 ${val.gift_status === 1 && !val.from ? 'is-invalid' : ''}`}
                                                            // className='chartinfo mb-0'
                                                            type="text"
                                                            placeholder='N/A'
                                                            value={val?.from}
                                                            required={val.gift_status === 1}
                                                            onChange={(e) => handleInputChange(index, 'from', e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} md={6}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>To</Form.Label>
                                                        <Form.Control
                                                            className={`chartinfo mb-0 ${val.gift_status === 1 && !val.to ? 'is-invalid' : ''}`}
                                                            // className='chartinfo mb-0'
                                                            type="text"
                                                            placeholder='N/A'
                                                            value={val?.to}
                                                            required={val.gift_status === 1}
                                                            onChange={(e) => handleInputChange(index, 'to', e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Message</Form.Label>
                                                        <Form.Control
                                                            className={`chartinfo mb-0 ${val.gift_status === 1 && !val.message ? 'is-invalid' : ''}`}
                                                            // className='chartinfo mb-0'
                                                            type="text"
                                                            placeholder='N/A'
                                                            value={val?.message}
                                                            required={val.gift_status === 1}
                                                            onChange={(e) => handleInputChange(index, 'message', e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* </Form> */}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Form>
                </Row>
                <div className="save-btn-div mt-5">
                    <button className="back-btn" onClick={() => setCurrentStep('shippingMethod')}> Back </button>
                    <button className="continue-btn" onClick={handleSubmit(onSubmit)}> Continue </button>
                    {/* <button className="continue-btn" onClick={handleNext} > Continue </button> */}
                </div>
            </section>
        </>
    );
};

export default Shipping_method;
