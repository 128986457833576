import React, { useEffect, useRef, useState, useContext } from "react";
import "./Header.css";
import {
  Container,
  InputGroup,
  Nav,
  NavDropdown,
  Navbar,
  Row,
  Form,
  Col,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGift, faHeart, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import Returnmodal from "../Common-Component/Commonmodal/Returnmodal/Returnmodal";
import HeaderOffcanvas from "../Common-Component/Offcanvas/HeaderOffcanvas/HeaderOffcanvas";
import LogoutModal from "../Common-Component/Commonmodal/LogoutModal/LogoutModal";
import Cookies from "js-cookie";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import { getallCategorySubcategory, Advertisement } from "../../utils/apis/master/Master";
import Cookies_popup_modal from "../Common-Component/Commonmodal/Cookies_popup_modal/Cookies_popup_modal";
import { occasion } from '../../utils/apis/Home/Homeapi';
import {
  Brands,
  Material,
  // Occasion,
  priceRange,
  allCategory,
} from "../../utils/apis/master/Master";

library.add(fas);
function Header() {
  const [isHovered, setIsHovered] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverShop, setHoverShop] = useState(false);
  const [click, setClick] = useState(false);
  const [clickShop, setClickShop] = useState(false);

  const handleClick = () => setClick(!click);
  // const handleMouseEnter = (id) => setHover(true);
  // const handleMouseLeave = (id) => setHover(false  );

  // const handleClickShop = () => setClickShop(!click);
  // const handleMouseEnterShop = () => setHoverShop(true);
  // const handleMouseLeaveShop = () => setHoverShop(false);

  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [showCategory, setshowCategory] = useState(false);
  const [showDropdownShop, setShowDropdownShop] = useState(false);
  const [showProductDropdownShop, setShowProductDropdownShop] = useState(false);


  const [activeDropdown, setActiveDropdown] = useState(null);


  console.log("activeDropdown", activeDropdown)


  const handleMouseEnterShopnew = () => {
    setActiveCategoryId(null);
    setActiveDropdown("shopBy");
    setActiveCategoryId(null);
  };

  const handleMouseEnterShopProductnew = () => {
    setActiveCategoryId(null);
    setActiveDropdown("shopProducts");
    setActiveCategoryId(null);
  };

  const handleMouseLeavenew = () => {
    setActiveDropdown(null); // Close dropdown on mouse leave
  };


  const handleClickShop = () => {
    setShowDropdownShop(true);
  };

  const handleMouseEnterShop = () => {
    setActiveCategoryId(null);
    setShowDropdownShop(true);
  };

  const handleMouseEnterShopProduct = () => {
    setShowProductDropdownShop(true);
  };

  const handleMouseLeaveShopProduct = () => {

    setShowProductDropdownShop(false);
  };

  const handleMouseLeaveShop = () => {

    setShowDropdownShop(false);
  };
  const handleMouseEnter = (id) => {
    setShowDropdownShop(false);
    setActiveDropdown(null);
    setActiveCategoryId(id);
    setshowCategory(true)
  };

  const handleMouseLeave = () => {
    setActiveCategoryId(null);
  };

  console.log("showDropdownShop", activeCategoryId)

  const handleNavItemClick = async (id) => {

    setActiveDropdown(null)

    setShowDropdownShop(false);
    setActiveCategoryId(id)
    if (activeCategoryId === id) {
      setActiveCategoryId(null);
    }
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
    // await setActiveCategoryId(null);
  };



  const handleCategoryClick = (id) => {
    setProductName('');
    // setActiveCategoryId(id);

  };

  // const handleNavItemClick = (selectedLink) => {
  //   console.log("llllllllllllll");

  //   setActiveCategoryId(null);
  //   setActiveLink(selectedLink);
  // const navbarToggle = document.querySelector(".navbar-toggler");
  // if (navbarToggle && window.innerWidth <= 992) {
  //   navbarToggle.click();
  // }
  // };

  // Show dropdown if hovered or clicked
  const showDropdown = hover || click;
  // const showDropdownShop = hoverShop || clickShop;

  const [showCookiesModal, setShowCookiesModal] = useState(true);

  const handleHideModal = () => {
    setShowCookiesModal(false);
  };

  const handleAcceptCookies = () => {
    setShowCookiesModal(false);
  };

  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });
  const navigate = useNavigate();
  const {
    cartCount,
    setAddCartModal,
    signin,
    setSignin,
    wishlistData,
    addCartModal,
    getData,
    updateCartCount,
    wishlistContext,
    productName,
    setProductName,
    isCookieAccepted,
    setIsCookieAccepted,
  } = useContext(Context);

  const [show, setShow] = useState(false);
  const [logoutshow, setLogoutshow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const [searchToggled, setIsToggled] = useState(false);
  const [showReturn, setShowreturn] = useState(false);
  const searchRef = useRef(null);
  const toggleClick = () => {
    setIsToggled(!searchToggled);
  };

  // };
  const handleLogout = () => {
    setLogoutshow(true); // Show the modal
  };
  const handleInputChange = (event) => {
    setProductName(event.target.value);
  };
  const handleInputChangesearch = () => {
    navigate("/product");
    // setProductName('');
  };

  const [data, setData] = useState();
  const [dataAdvertisement, setDataAdvertisement] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await getallCategorySubcategory(2);
    if (res?.success) {
      setData(res?.data);
      setProductName('');
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const getAllDataAdvertisement = async () => {
    const res = await Advertisement();
    if (res?.success) {
      setDataAdvertisement(res?.data);
      setProductName('');
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [occasionprod, setOcassionprod] = useState([]);

  const getOcassion = async () => {
    const res = await occasion();
    if (res?.success) {
      setOcassionprod(res?.data)
    }
  }



  useEffect(() => {
    getOcassion();
    getAllDataAdvertisement();
    getAllData();
  }, []);

  const [priceData, setpriceData] = useState({});
  const getAllPrice = async () => {
    const res = await priceRange();
    if (res?.success) {
      setpriceData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    getAllPrice();
  }, []);

  const minPrice = parseFloat(priceData.minPrice);
  const maxPrice = parseFloat(priceData.maxPrice);

  // Calculate the interval for each range segment
  const interval = (maxPrice - minPrice) / 8;
  const priceRanges = [];

  // Generate 8 price ranges
  for (let i = 0; i < 8; i++) {
    const rangeMin = Math.round(minPrice + interval * i);
    const rangeMax = Math.round(minPrice + interval * (i + 1));
    priceRanges.push({ min: rangeMin, max: rangeMax });
  }
  return (
    <>
      <section className="Main-header">
        <div className="container-fluid p-0">
          {/* <!-- start header all section  --> */}
          <div className="header-all-section">
            {/* <!--top-header section start--> */}
            {dataAdvertisement?.length > 0 && (
              <section className="top-header ">
                <div className="container-fluid">
                  <div className="row">
                    <marquee direction="left">
                      {dataAdvertisement?.map((val) =>
                        <span className="marquee-content">
                          {val?.name}
                        </span>)}
                      {/* <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our
                      New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our
                      New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our
                      New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our
                      New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our
                      New And Exciting Product Lineup!"
                    </span> */}
                    </marquee>
                  </div>
                </div>
              </section>)}
            {/* start-second-header */}

            <section className="second-header">
              <Container className="">
                <div className="top-second-header">
                  <Row className="">
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-5 col-5">
                      <div className="logo">
                        <Link
                          to="/home"
                          onClick={() => setShowCookiesModal(true)}
                        >
                          <img
                            className="headlogo"
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/image/logo/header-logo.png"
                            }
                            alt="Logo"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6 col-sm-2 col-1">
                      <div className="desktop-view-search">
                        <InputGroup className="">
                          <Form.Control
                            placeholder="what are you looking for?"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={productName}
                            onChange={handleInputChange}
                          />
                          <InputGroup.Text id="basic-addon1">
                            <FontAwesomeIcon
                              icon="fa-solid fa-magnifying-glass"
                              onClick={handleInputChangesearch}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-5 col-6">
                      <div className="cart-logo d-flex ">
                        <div className="mobile-view-search text-center">
                          <FontAwesomeIcon
                            icon="fa-solid fa-magnifying-glass"
                            onClick={toggleClick}
                          />
                        </div>
                        <div className="icon-cart">
                          <Link to={"/mycart"}>
                            <FontAwesomeIcon icon="fa-solid fa-cart-shopping" />
                            <span className="ms-lg-2 ms-md-1 ms-sm-1 ms-2">
                              ({cartCount})
                            </span>
                          </Link>
                        </div>

                        {signin ? (
                          <>
                            <div className="icon-cart ">
                              <Link to={"/wishlist"}>
                                <FontAwesomeIcon icon="fa-solid fa-heart" />
                                <span className="ms-lg-2 ms-md-1 ms-sm-1 ms-2">
                                  ({wishlistData?.length})
                                </span>
                              </Link>
                            </div>

                            <div className="icon-cart ms-lg-2 ms-md-2 ms-sm-1 ms-1">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="usertogggle"
                                  id="dropdown-basic"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-user" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="profile-logout-drop">
                                  <Dropdown.Item as={Link} to="/profile">
                                    Profile
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item href="#/action-2" onClick={() => setLogoutshow(true)}>Log-out</Dropdown.Item> */}
                                  <Dropdown.Item onClick={handleLogout}>
                                    Log-out
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="icon-cart ">
                              <Link to={"/wishlist"}>
                                <FontAwesomeIcon icon="fa-solid fa-heart" />
                                <span className="ms-lg-2 ms-md-1 ms-sm-1 ms-2">
                                  (0)
                                </span>
                              </Link>
                            </div>

                            <div className="icon-cart ms-lg-2 ms-md-2 ms-sm-1 ms-1">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="usertogggle"
                                  id="dropdown-basic"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-user" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="profile-logout-drop">
                                  <Dropdown.Item as={Link} to="/register">
                                    Sign-up
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item href="#/action-2" onClick={() => setLogoutshow(true)}>Log-out</Dropdown.Item> */}
                                  <Dropdown.Item as={Link} to="/login">
                                    Log-in
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {searchToggled && (
                      <div ref={searchRef} className="input-group-box mb-3">
                        <Form.Control type="text" placeholder="Search here" />
                      </div>
                    )}
                  </Row>
                </div>
                <Navbar expand="lg" className=""

                  // onMouseLeave={() => {
                  //   handleMouseLeaveShopProduct()
                  //   handleMouseLeaveShop()
                  //   handleMouseLeave()
                  // }}
                  onMouseLeave={() => {
                    handleMouseLeavenew()
                    handleMouseLeave()
                  }}

                >
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Navbar.Collapse id="basic-navbar-nav" className="menus-main">
                    <Nav className="">
                      <Nav.Link
                        as={Link}
                        to="/home"
                        active={activeItem === "home"}
                        onClick={handleNavItemClick}
                        className="commonLink"
                      >
                        Home
                      </Nav.Link>

                      {data?.slice(0, 5).map((val) => (
                        <NavDropdown
                          key={val?.id}
                          title={val?.name}
                          id="basic-nav-dropdown"
                          className="me-xxl-3 me-xl-3 me-lg-1 nav-dropdown"
                          onMouseEnter={() => handleMouseEnter(val?.id)}
                          onClick={() => handleCategoryClick(val?.id)}
                          show={activeCategoryId === val?.id}
                        >
                          {val?.sub_categories?.map((val1) => (
                            <>
                              {
                                activeCategoryId != null ? (
                                  <NavDropdown.Item
                                    key={val1?.id}
                                    as={Link}
                                    to={`/emp-satifaction/${val1?.id}`}


                                    onClick={() => handleNavItemClick(val?.id)}
                                  >
                                    {val1?.name}
                                  </NavDropdown.Item>) : null
                              }
                            </>)
                          )}
                        </NavDropdown>
                      ))}



                      {/* <NavDropdown title="Trophies" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="/product-customise" onClick={handleNavItemClick}>customise</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Drinkware" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="/product-customise" onClick={handleNavItemClick}>customise</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Photoframes" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="#" onClick={handleNavItemClick}>customise</NavDropdown.Item>
                      </NavDropdown>

                      <Nav.Link as={Link} to="#" active={activeItem === ""} onClick={handleNavItemClick} className="commonLink">
                        Silver Plated Artefacts
                      </Nav.Link>

                      <NavDropdown title="Shop Promotional Products" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to={'/shop-promotional'} onClick={handleNavItemClick}>Coins</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={'/shop-promotional'} onClick={handleNavItemClick}>Medals</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>
                          Plaques
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Gadgets</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Trophies</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Drinkware</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Wardrobe</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Home Decor</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Gourmet Store</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Bags & Luggage</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Office Essentials</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Health and Wellness</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Sustainable Products</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Gift Hampers and Sets</NavDropdown.Item>
                      </NavDropdown>

                       */}
                      {/* <NavDropdown
                        title="Shop by"
                        id="basic-nav-dropdown"
                        className="me-xxl-3 me-xl-3 me-lg-1"
                        // onClick={handleClickShop}
                        onMouseEnter={handleMouseEnterShop}
                        onMouseLeave={handleMouseLeaveShop}
                        show={showDropdownShop}
                      >
                        <NavDropdown.Item
                          as={Link}
                          to={"/sustainable-product"}
                          onClick={handleNavItemClick}
                        >
                          Price
                        </NavDropdown.Item>
                    
                        <NavDropdown.Item
                          as={Link}
                          to={"/all-category"}
                          onClick={handleNavItemClick}
                        >
                          {" "}
                          Categories{" "}
                        </NavDropdown.Item>
                    
                        <NavDropdown.Item
                          onClick={handleNavItemClick}
                          as={Link}
                          to="/shopbyoccasion"
                        >
                          Occasions / Events
                        </NavDropdown.Item>
                      </NavDropdown> */}


                      <div className="shop-by-nav">

                        <NavDropdown
                          title="Shop Products"
                          id="basic-nav-dropdown"
                          className="me-xxl-3 me-xl-3 me-lg-1"
                          onMouseEnter={handleMouseEnterShopProductnew}

                          show={activeDropdown === "shopProducts"}

                        >
                       
                            <div className="shop-product-tooltip">

                              {data?.map((val) => {


                                return (
                                  <>
                                    {activeDropdown !== null && (
                                      <div className="tooltip-column">
                                    <p className="shop-product-cat_heading" style={{ textTransform: 'uppercase' }}>{val?.name}</p>

                                        {val?.sub_categories?.map((sub) => {
                                          return (
                                            <NavDropdown.Item
                                            className="shop-product-cat-item"
                                              key={sub?.id}
                                              as={Link}
                                              to={`/emp-satifaction/${sub?.id}`}

                                              onClick={() => handleNavItemClick(val?.id)}

                                            >
                                              {sub?.name}
                                            </NavDropdown.Item>
                                          )
                                        })}
                                      </div>
                                    )}
                                  </>


                                )
                              })}
                            </div>
                          

                        </NavDropdown>


                      </div>


                      <div className="shop-by-nav">

                        <NavDropdown
                          title="Shop by"
                          id="basic-nav-dropdown"
                          className="me-xxl-3 me-xl-3 me-lg-1"
                          onMouseEnter={handleMouseEnterShopnew}
                          show={activeDropdown === "shopBy"}

                        >
                          {/* <div className="shop-by-tooltip">
                            
                            <div className="tooltip-column">
                              <h4>Price</h4>
                              <NavDropdown.Item as={Link} to="/price-low-to-high" onClick={handleNavItemClick}>
                                Low to High
                              </NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/price-high-to-low" onClick={handleNavItemClick}>
                                High to Low
                              </NavDropdown.Item>
                            </div>
                          </div> */}

                          <>

                            {activeDropdown !== null && (
                              <div className="shop-by-tooltip">
                                {/* Price Column */}
                                <div className="tooltip-column">
                                  <p className="shop-product-cat_heading">Interest</p>
                                  {data?.map((val) =>
                                    <NavDropdown.Item className="shop-product-cat-item" as={Link} to={`all-category/${val?.id}`} onClick={handleNavItemClick}>
                                      {val?.name}
                                    </NavDropdown.Item>)}
                                  {/* <NavDropdown.Item as={Link} to="/price-high-to-low" onClick={handleNavItemClick}>
                                 High to Low
                               </NavDropdown.Item> */}
                                </div>

                                <div className="tooltip-column">
                                  <p className="shop-product-cat_heading">Price</p>
                                  {priceRanges.map((range, index) => (
                                    <NavDropdown.Item
                                    className="shop-product-cat-item"
                                      as={Link}
                                      to={`/product?minprice=${range.min}&maxprice=${range.max}`}
                                      onClick={() => {
                                        console.log(`Selected price range: ₹${range.min} - ₹${range.max}`)
                                        setActiveDropdown(null)
                                      }}
                                      key={index}
                                    >
                                      ₹{range.min} - ₹{range.max}
                                    </NavDropdown.Item>
                                  ))}
                                  {/* <NavDropdown.Item as={Link} to="/price-low-to-high" onClick={handleNavItemClick}>
                                 Low to High
                               </NavDropdown.Item>
                               <NavDropdown.Item as={Link} to="/price-high-to-low" onClick={handleNavItemClick}>
                                 High to Low
                               </NavDropdown.Item> */}
                                </div>


                                <div className="tooltip-column">
                                  <p className="shop-product-cat_heading">Occasions</p>
                                  {occasionprod?.map((val) =>
                                    <NavDropdown.Item className="shop-product-cat-item" as={Link} to={`product/${val?.id}`} onClick={handleNavItemClick}>
                                      {val?.name}
                                    </NavDropdown.Item>)}
                                  {/* <NavDropdown.Item as={Link} to="/price-high-to-low" onClick={handleNavItemClick}>
                                 High to Low
                               </NavDropdown.Item> */}
                                </div>

                              </div>

                            )}


                            </>


                            {/* <NavDropdown.Item
                            as={Link}
                            to="/sustainable-product"
                            onClick={handleNavItemClick}
                          >
                            Price
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            as={Link}
                            to="/sustainable-product"
                            onMouseLeave={handleMouseLeaveShop}
                            onClick={handleNavItemClick}
                          >
                            Interest
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={Link}
                            to="/all-category"
                            onMouseLeave={handleMouseLeaveShop}
                            onClick={handleNavItemClick}
                          >
                            Categories
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={handleNavItemClick}
                            onMouseLeave={handleMouseLeaveShop}
                            as={Link}
                            to="/shopbyoccasion"
                          >
                            Occasions / Events
                          </NavDropdown.Item> */}
                          </NavDropdown>


                      </div>

                      <Nav.Link
                        as={Link}
                        to="/customizable-product"
                        active={activeItem === "customizable-product"}
                        onClick={handleNavItemClick}
                        className={`commonLink Customise-link ${isHovered ? "Customise-heartbeat" : ""
                          }`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        Customise{" "}
                        <FontAwesomeIcon
                          className="Customise-gift-icon"
                          icon={faGift}
                        />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/offers"
                        active={activeItem === "offers"}
                        onClick={handleNavItemClick}
                        className="commonLink offer-link"
                      >
                        Offers
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Container>
            </section>

            {/* Mobile Header start */}

            <section className="mobileSecond-header">
              <div className="secondHeaderMain">
                <Container className="">
                  <div className="top-second-header">
                    <div className="logo">
                      <Link to="/home">
                        <img
                          className="headlogo"
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/logo/header-logo.png"
                          }
                          alt="Logo"
                        />
                      </Link>
                    </div>

                    <Row className="pb-1">
                      <Col xs={4}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-bars"
                          onClick={handleShow}
                          className="barsIcon"
                        />
                      </Col>
                      <Col xs={8}>
                        <div className=" cart-logo d-flex ">
                          <div className="icon-cart mobile-view-search text-center">
                            <Link to={"/search-products"}>
                              <FontAwesomeIcon
                                icon="fa-solid fa-magnifying-glass"
                                onClick={toggleClick}
                                className="commonIcon"
                              />
                            </Link>
                          </div>
                          <div className="icon-cart">
                            <Link to={"/mycart"}>
                              <div className="bageicn_mian">
                                <FontAwesomeIcon
                                  icon="fa-solid fa-cart-shopping"
                                  className="commonIcon"
                                />
                                <div className="bageicn">{cartCount}</div>
                              </div>
                            </Link>
                          </div>


                          <div className="icon-cart ">
                            <Link to={"/wishlist"}>
                              <div className="bageicn_mian">
                                <FontAwesomeIcon
                                  icon={faHeart}
                                  className="icon commonIcon"
                                />
                                <div className="bageicn">{wishlistData?.length}</div>
                              </div>
                            </Link>
                          </div>


                          {signin ? (
                            <>
                              <div className="icon-cart ms-lg-3 ms-md-2 ms-sm-1 ms-1">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="icon-cart usertogggle"
                                    id="dropdown-basic"
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-user"
                                      className="commonIcon"
                                    />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="profile-logout-drop">
                                    <Dropdown.Item as={Link} to="/profile">
                                      Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#/action-2"
                                      onClick={handleLogout}
                                    >
                                      log-out
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </>

                          ) : (
                            <>

                              <div className="icon-cart ms-lg-3 ms-md-2 ms-sm-1 ms-1">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="icon-cart usertogggle"
                                    id="dropdown-basic"
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-user"
                                      className="commonIcon"
                                    />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="profile-logout-drop">
                                    <Dropdown.Item as={Link} to="/register">
                                      Sign-up
                                    </Dropdown.Item>

                                    <Dropdown.Item as={Link} to="/login">
                                      Log-in
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </>
                          )}
                        </div>


                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </section>

            <HeaderOffcanvas
              data={data}
              placement="start"
              name={"start"}
              show={show}
              handleClose={handleClose}
            />
            <LogoutModal
              show={logoutshow}
              onHide={() => setLogoutshow(false)}
              text="Are you sure you want to log out?"
              setSignin={setSignin}
              updateCartCount={updateCartCount}
            />
            {/* <LogoutModal show={logoutshow} onHide={() => setLogoutshow(false)} text={'Are You Sure You want to Log Out'} /> */}
            {/* Mobile Header start */}
            {/* end-second-header */}
            {/* <!-- end header all section  --> */}
          </div>
        </div>
      </section>

      {!isCookieAccepted && (
        <Cookies_popup_modal
          isCookieAccepted={isCookieAccepted}
          setIsCookieAccepted={setIsCookieAccepted}
          show={showCookiesModal}
          onHide={handleHideModal}
          onAcceptCookies={handleAcceptCookies}
        />
      )}
    </>
  );
}

export default Header;