import React, { useEffect, useRef, useState, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './HomeAbout.css'
import CountUp from 'react-countup'
import { Context } from "../../../utils/context";

const HomeAbout = ({data}) => {
    
    const { IMG_URL } = useContext(Context);
   

    
    return (
        <>
            <section className='home-about'>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <div className='heading-holder'>
                                <div className='d-flex'>
                                    <h2 className='first-heading'>About  </h2>
                                    <h3 className='second-heading'>Us</h3>
                                </div>
                                <p className='text-para'>MittalCraftworks.com is designed to bring you a seamless experience</p>
                                <h2 className='text-content'>in selecting premium, personalised, and bespoke gifts.</h2>
                                <p className='text-para'>From customised corporate mementos to finely crafted luxury items, our platform is dedicated to delivering quality, convenience, and exclusivity directly to your doorstep.</p>
                                <div className='btn-holder'>
                                    {/* <button className="btn btn-2 hover-slide-up">
                                        <span>Read More</span>
                                       
                                    </button> */}
                                    <button className="btn btn-2 hover-slide-up" onClick={() => window.location.href = '/aboutUs'}>
                                        <span>Read More</span>
                                    </button>

                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <Row>
                                <Col lg={6} md={6}>
                                    <div className='white-box mb-4'>
                                        <div className='total-holder text-center'>
                                            
                                            
                                            {/* <h4><CountUp start={0} end={data?.happy_customer} duration={4} decimals={0} /> </h4> */}
                                            <h4>{data?.happy_customer}</h4>
                                            <p>Happy Customers</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className='white-box mb-4'>
                                        <div className='total-holder text-center'>               
                                            {/* <h4><CountUp start={0} end={data?.hour_saved} duration={4} decimals={0} /></h4> */}
                                            <h4>{data?.hour_saved}</h4>
                                            <p>Hrs saved</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className='white-box'>
                                <div className='total-holder text-center '>
                                    <div className='d-flex justify-content-center'>
                                      
                                        {/* <h4><CountUp start={0} end={data?.grateful_recipients} duration={4} decimals={0} /></h4> */}
                                        <h4>{data?.grateful_recipients}</h4>
                                        <p className='ms-4 mt-lg-3 mt-md-2 mt-2'>Grateful Recipients</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HomeAbout
