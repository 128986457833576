import React, { useEffect, useRef, useState, useContext } from 'react'
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "./Offers.css"
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import { Context } from "../../utils/context";
import {Offersdata} from "../../utils/apis/master/Master";
import Sesonal_offers from '../Common-Component/Commonmodal/Sesonal_offers/Sesonal_offers';
const Offers = () => {

    const [modalShow, setModalShow] = React.useState(false);

    const offers = [
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg2.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" }
    ]

    const items = [
        { text: "HOME", link: "/home" },
        { text: "OFFERS" },
    ]


    const [data, setData] = useState();
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await Offersdata();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
      
    }, []);
    return (
        <>
            <section className='Offers'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='swiperoffer'>
                        <Swiper
                            slidesPerView={"auto"}
                            modules={[Autoplay, Pagination]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                370: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1440: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                2000: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                            }}

                            pagination={{ clickable: true }}
                            className="mySwiper"
                        >
                            {data?.map(offer => (
                                <SwiperSlide>
                                    <img src={IMG_URL+offer?.image} className='offerImg'  onClick={() => setModalShow(false)}/>


                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>
                </div>
            </section>

            <Sesonal_offers
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
        </>
    )
}

export default Offers