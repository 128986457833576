import React, { useState,useEffect } from "react";
import './Payment_checkout.css'
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import Billing_information from "./step1/Billing_information";
import Shipping_information from "./step2/Shipping_information";
import Shipping_method from "./step3/Shipping_method";
import Payment_info from "./step4/Payment_info";
import Order_review from "./step5/Order_review";
import RewardDetails from "../RewardDetails/RewardDetails";
import Payu from "./step5/payu";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import {
    postAddress,
    getAddress,
    deleteAddress,
    getSingleAddress,
    postorder, postPayu,postPayment,
  } from "../../utils/apis/orderProcess/orderProcess";
const Payment_checkout = () => {
    const [current, setCurrent] = useState(1);
    const [addressSelect, setAddressSelect] = useState("");
    const [ShippingId, setShippingId] = useState(null);
    const [address_id, setAddress_id] = useState();
    const [giftdata, setGiftData] = useState();
    const [giftdataArray, setGiftDataarray] = useState([]);
    const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(0);
    const [checkout, setCheckout] = useState(true);
    const [giftOption, setGiftOption] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState("COD");
    const [currentStep, setCurrentStep] = useState('shippingMethod');
    const [giftOptionsEntireOrder, setGiftOptionsEntireOrder] = useState(true);
    const [giftOptionsIndividualItems, setGiftOptionsIndividualItems] = useState(false);
    const [totalGiftInduasalPrice, setTotalGiftInduasalPrice] = useState(0);
    const [paymentData, setPaymentData] = useState(null);
    const [dataId, setdataID] = useState();
    const stepLabels = [
        { id: 1, label: "Billing Information" },
        { id: 2, label: "Shipping Information" },
        { id: 3, label: "Shipping Method" },
        { id: 4, label: "Payment Information" },
        { id: 5, label: "Order Review" },
    ];

    const handleNext = () => {
        if (current < 5) {
            setCurrent(current + 1);
        }
    };

    const handlePrevious = () => {
        if (current > 1) {
            setCurrent(current - 1);
        }
    };

    const isStepCompleted = (step) => {
        return step < current;
    };

    const breadcrumbItems = [
        { text: "HOME", link: "/", className: "active" },
        { text: "MY CART", link: "/mycart", className: "active" },
        { text: "CHECKOUT", link: "/product-cart-inner", className: "active" },
    ];
    const [userData, setUserData] = useState(null);
    const [isProcessed, setIsProcessed] = useState(true); // Track if transaction has been processed
    const [orderplace, setOrderplace] = useState(false);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const productinfo = urlParams.get('productinfo');
        const mihpayid = urlParams.get('mihpayid');
        console.log(mihpayid, "mihpayid");
        console.log(productinfo, "productinfo");


        const processTransaction = async () => {
            if (mihpayid) { // Check if mihpayid exists and is not already processed
                setUserData(mihpayid);
                // setIsProcessed(false); // Set to true to prevent reprocessing

                const data = {
                    productinfo,
                    mihpayid,
                };

                try {
                    const response = await postPayment(data);

                    if (response?.success) {
                        setOrderplace(true);
                        setIsProcessed(false);
                        // Timeout to redirect after showing the modal
                        setTimeout(() => {
                            setOrderplace(false); // Close the modal
                            setUserData(null);
                            window.location = "/home";
                        }, 3000);
                    } else {
                        console.error("Payment failed:", response?.message);
                    }
                } catch (error) {
                    console.error("Error processing payment:", error);
                    setOrderplace(false); // Ensure modal closes in case of error
                }
            }
        };

        processTransaction(); // Call the async function

    }, [isProcessed]);



    return (
        <>
            <section className='Payment_checkout-section'>
                <div className="container">
                    {checkout ? (
                        <>
                            <Breadcrumbs items={breadcrumbItems} />

                            <div className="">
                                <section className="Payment_stepform_sec">
                                    <Form>
                                        <div className="signup-form ">
                                            <div className="progress-bar-container">
                                                <div className="step-row">
                                                    {stepLabels?.map((item, index) => (
                                                        <div key={index + 1} className={`step-container ${index + 1 === current ? "active" : ""}`}>
                                                            <div className="main-flex-div">
                                                                <div className="circle-center">
                                                                    <div
                                                                        className={`line ${isStepCompleted(index + 1)
                                                                            ? "completed"
                                                                            : ""
                                                                            } ${index + 1 === current ? "active" : ""}`}
                                                                    >
                                                                        <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                                                                    </div>
                                                                </div>
                                                                <div className="centerDivcircle">
                                                                    <span className={`step-text ${isStepCompleted(index + 1) ? "completed" : ""} ${index + 1 === current ? "active" : ""}`}>{item.label}</span>
                                                                </div>
                                                            </div>
                                                            <div className='circle-center'>
                                                                <div className={`circle ${isStepCompleted(index + 1) ? "completed" : ""}`}></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wizard-form mt-3">
                                            {current === 1 && (
                                                <Billing_information ShippingId={ShippingId} setAddress_id={setAddress_id} handleNext={handleNext} address_id={address_id} setAddress_id={setAddress_id} addressSelect={addressSelect} setAddressSelect={setAddressSelect} setIsShippingSameAsBilling={setIsShippingSameAsBilling} isShippingSameAsBilling={isShippingSameAsBilling} />
                                            )}
                                            {current === 2 && (
                                                <Shipping_information
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                    setPaymentMethod={setPaymentMethod}
                                                    address_id={address_id}
                                                    isShippingSameAsBilling={isShippingSameAsBilling}
                                                    ShippingId={ShippingId}
                                                    setShippingId={setShippingId}

                                                />
                                            )}
                                            {current === 3 && (
                                                <Shipping_method
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                    setGiftOptionsIndividualItems={setGiftOptionsIndividualItems}
                                                    giftOptionsIndividualItems={giftOptionsIndividualItems}
                                                    setGiftOptionsEntireOrder={setGiftOptionsEntireOrder}
                                                    giftOptionsEntireOrder={giftOptionsEntireOrder}
                                                    setCurrentStep={setCurrentStep}
                                                    currentStep={currentStep}
                                                    setGiftDataarray={setGiftDataarray}
                                                    giftdataArray={giftdataArray}
                                                    setGiftData={setGiftData}
                                                    giftdata={giftdata}
                                                    setGiftOption={setGiftOption}
                                                    giftOption={giftOption}
                                                    setTotalGiftInduasalPrice={setTotalGiftInduasalPrice}
                                                    totalGiftInduasalPrice={totalGiftInduasalPrice}
                                                />
                                            )}
                                            {current === 4 && (
                                                <Payment_info
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    ShippingId={ShippingId}
                                                    paymentMethod={paymentMethod}
                                                    setPaymentMethod={setPaymentMethod}
                                                    setPaymentData={setPaymentData}
                                                    paymentData={paymentData}
                                                    address_id={address_id}
                                                    giftdata={giftdata}
                                                    giftdataArray={giftdataArray}
                                                    setdataID={setdataID}
                                                    dataId={dataId}


                                                />
                                            )}
                                            {current === 5 && (
                                                // <Order_review
                                                //     handleNext={handleNext}
                                                //     handlePrevious={handlePrevious}
                                                //     addressSelect={addressSelect}
                                                //     paymentMethod={paymentMethod}
                                                //     address_id={address_id}
                                                //     ShippingId={ShippingId}
                                                //     giftdata={giftdata}
                                                //     giftdataArray={giftdataArray}
                                                // />
                                                <Payu
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                    address_id={address_id}
                                                    ShippingId={ShippingId}
                                                    giftdata={giftdata}
                                                    giftdataArray={giftdataArray}
                                                    setPaymentData={setPaymentData}
                                                    paymentData={paymentData}
                                                    setdataID={setdataID}
                                                    dataId={dataId}
                                                />
                                            )}
                                        </div>
                                    </Form>
                                </section>
                            </div>
                        </>
                    ) : (
                        <RewardDetails setCheckout={setCheckout} />
                    )}
                </div>
            </section>
            <SuccessfullyModal
                show={orderplace}
                onHide={() => setOrderplace(false)}
                text={"Order Placed Successfully"}
            />
        </>
    );
}

export default Payment_checkout;
