import React, { useEffect, useRef, useState, useContext } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContactUsForm } from "../../utils/apis/form/form";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { RegxExpression } from "../../utils/common";
import {
  getProductVariant,
  getVariant,
} from "../../utils/apis/Product/Product";
import { Material } from "../../utils/apis/master/Master";
const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);
  const [value, setValue] = useState();
  const [isMapVisible, setIsMapVisible] = useState(false); // New state for map visibility

  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
    setIsMapVisible(true); // Show the map
  };
  const [showSuccess, setShowsuccess] = useState(false);

  const fileInputRef = useRef(null);
  const { id } = useParams();
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    IMG_URL,
  } = useContext(Context);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const customiseClose = () => {
    setShowsuccess(true);
    setTimeout(() => {
      setShowsuccess(false);
      window.location.pathname = "/home";
    }, 3000);
  };

  console.log("errors", errors)

  const onSubmit = async (data) => {
    setShimmerLoader(true);

    const formData = new FormData();
    formData.append("subject", data?.subject);
    formData.append("message", data?.message);
    formData.append("agree_status", data?.agree_status);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);

    const res = await ContactUsForm(formData);
    if (res?.success) {
      customiseClose();
    } else {
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact) {
        setError("contact_no", { message: res?.message?.contact });
      }
    }
  };

  const handleimageClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  const handleCloseMap = () => {
    setShowForm(false);
    setShowBg(false);
    setShowContactTextHolder(true);
    setIsMapVisible(false); // Hide the map
  };



  return (
    <>
      <section className="contact-us  ">


        <div
          className={`map-section ${isMapVisible ? "show-map " : "hide-map "}`}
        >
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                src="https://maps.google.com/maps?q=22.717837,75.884794&hl=en&z=14&output=embed"

                allowFullScreen=""
                loading="lazy"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
              {showBg && (
                <button className="close-map-btn" onClick={handleCloseMap}>
                  X
                </button>
              )}
            </div>
          </div>
        </div>


        {/* <div
          className={`map-section ${isMapVisible ? "show-map " : "hide-map "}`}
        >
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
              {showBg && (
                <button className="close-map-btn" onClick={handleCloseMap}>
                  X
                </button>
              )}
            </div>
          </div>
        </div> */}

        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div
              className={`contactusmain ${showBg ? "contact_form_none" : ""}`}
            >
              <div className="heading-holder mb-4">
                <h2>Contact Us</h2>
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-5 col-md-7">
                  <div
                    className={`form-holder ${showBg ? "slide-out-left" : ""}`}
                  >
                    <div
                      className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                        }`}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assest/Icon/arrow-left.png"
                        }
                        className="left-arrow"
                      />
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row className="form_container">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            {...register("name", {
                              required: "Name is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.name,
                              "is-valid": getValues("name"),
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.name.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors?.email && (
                            <sup className="text-danger mt-3">
                              {errors?.name?.message}
                            </sup>
                          )}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1 ">
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                              "is-valid": getValues("email"),
                            })}
                          />
                          {errors?.email && (
                            <sup className="text-danger mt-3">
                              {errors?.email?.message}
                            </sup>
                          )}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control
                            type="text"
                            name="subject"
                            placeholder="Enter Subject"
                            {...register("subject", {
                              validate: (value) =>
                                value || getValues("message")
                                  ? true
                                  : "Subject or Message is required",
                              // required: "subject required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.subject,
                              "is-valid": getValues("subject"),
                            })}
                          />
                          {errors?.subject && (
                            <sup className="text-danger">
                              {errors?.subject?.message}
                            </sup>
                          )}
                        </Form.Group>
                        <div className="mb-3">
                          <Controller
                            name="contact_no"
                            control={control}
                            rules={{
                              required: "Field is required",
                              pattern: {
                                value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                                message:
                                  "Invalid number format. Please enter a valid number with an optional country code",
                              },
                            }}
                            render={({ field }) => (
                              <PhoneInput
                                country={"in"}
                                {...field}
                                className={classNames("", {
                                  "is-invalid": errors?.contact_no,
                                  "is-valid": getValues("contact_no"),
                                })}
                                onChange={(value) => field.onChange(value)}
                                type="text"
                                placeholder="Enter Mobile Number"
                                onKeyDown={(event) => {
                                  if (!RegxExpression.phone.test(event.key)) {
                                    if (event.key !== "Backspace") {
                                      event.preventDefault();
                                    }
                                  }
                                }}
                                maxLength={10}
                              />
                            )}
                          />
                          {errors?.contact_no && (
                            <sup className="text-danger mt-3">
                              {errors?.contact_no?.message}
                            </sup>
                          )}
                        </div>

                        {/* <Form.Group controlId="exampleForm.ControlInput1">
                        <Controller
                          name="contact_no"
                          control={control}
                          rules={{
                            required: "Field is required",
                            pattern: {
                              value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                              message: "Invalid number format. Please enter a valid number with an optional country code",
                            },
                          }}
                          render={({ field }) => (
                            <PhoneInput
                            country={'in'}
                              {...field}
                              className={classNames("", {
                                "is-invalid": errors?.contact_no,
                                "is-valid": getValues("contact_no"),
                              })}
                              onChange={(value) => field.onChange(value)}
                              type="text"
                              placeholder="Enter Mobile Number"
                              onKeyDown={(event) => {
                                if (!RegxExpression.phone.test(event.key)) {
                                  if (event.key !== "Backspace") {
                                    event.preventDefault();
                                  }
                                }
                              }}
                              maxLength={10}
                            />
                          )}
                        />
                         </Form.Group>
                        {errors?.contact_no && (
                          <sup className="text-danger mt-1">
                            {errors?.contact_no?.message}
                          </sup>
                        )} */}


                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            type="text"
                            name="message"
                            placeholder="Enter Message"
                            {...register("message", {
                              validate: (value) =>
                                value || getValues("subject")
                                  ? true
                                  : "Message or Subject is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.message,
                              "is-valid": getValues("message"),
                            })}
                          />

                          {errors?.message && (
                            <sup className="text-danger">
                              {errors?.message?.message}
                            </sup>
                          )}
                        </Form.Group>



                        {/* <Controller
                          name="agree_status"
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="I have read and agree to the Privacy Policy."
                              className="agree_status"
                              onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                            />
                          )}
                        /> */}
                        <Controller
                          name="agree_status"
                          control={control}
                          defaultValue={0}
                          rules={{
                            validate: (value) =>
                              value === 1 ||
                              "You must agree to the Privacy Policy",
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <Form.Check
                                type="checkbox"
                                label="I have read and agree to the Privacy Policy."
                                className={`agree_status ${error ? "is-invalid" : ""
                                  }`}
                                onChange={(e) =>
                                  field.onChange(e.target.checked ? 1 : 0)
                                }
                                isInvalid={!!error}
                              />
                              {error && (
                                <Form.Control.Feedback type="invalid">
                                  {error.message}
                                </Form.Control.Feedback>
                              )}
                            </>
                          )}
                        />

                        <div className="btn-holder">
                          <button className="sendMessage" type="submit">
                            Send Message
                          </button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>

                <div className="col-lg-7 col-md-5">
                  <div
                    className={`contact-text-holder ${showBg ? "slide-out-right" : ""
                      }`}
                  >
                    <div className="d-flex mb-3">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-phone"
                        />
                      </div>
                      <div className="address-holder">
                        <p>+91 9302911233</p>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-envelope"
                        />
                      </div>
                      <div className="address-holder">
                        <p>sales@mittalcraftworks.com</p>
                      </div>
                    </div>

                    {/* <div className="d-flex">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-location-dot"
                        />
                      </div>
                      <div className="address-holder">
                        <p>
                          516/4, Gali Rajan Kalan, Kashmere <br /> Gate Delhi,
                          Delhi, 110006
                        </p>
                      </div>
                    </div> */}

                    <div className="d-flex">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-map-location-dot"
                        />
                      </div>
                      <div
                        className="address-holder map-text"
                        onClick={handleMapTextClick}
                      >
                        <p>Find Us on Map</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className=""></div>
        </div>
      </section >
      <SuccessfullyModal
        show={showSuccess}
        onHide={() => setShowsuccess(false)}
        text={"Your Request has been send successfully"}
      />
    </>
  );
};

export default ContactUs;
